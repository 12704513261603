<template>
  <div class="text" :style="style">{{ userProps.text }}</div>
</template>

<script lang="ts" setup>
import { StyleValue, computed } from 'vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{ userProps: WidgetProps<'text-output'> }>();

function fontSize(size: any) {
  switch (size) {
    case 'small':
      return '12px';
    case 'medium':
      return '16px';
    case 'large':
      return '24px';
    default:
      return '16px';
  }
}

const style = computed<StyleValue>(() => ({
  fontSize: fontSize(props.userProps.size),
}));
</script>
