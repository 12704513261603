<template>
  <div class="date-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      ref="inputRef"
      v-model="internalValue"
      class="date"
      type="date"
      max="2999-12-31"
      :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      @input="handleInput"
      @keyup.delete="clearSelection"
      @blur="setErrors"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'date-input'>;
  value: WidgetProps<'date-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'date-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const internalValue = ref(props.value);
const inputRef = ref<HTMLInputElement>();

watchEffect(() => {
  internalValue.value = props.value;
});

const clearSelection = () => {
  if (!inputRef.value?.validity.badInput) return;
  internalValue.value = '';
  emit('update:errors', []);
  emit('update:value', '');
};

const handleInput = () => {
  if (internalValue.value?.length !== 'YYYY-MM-DD'.length) {
    return;
  }
  const [year, month, day] = internalValue.value.split('-');
  emit('update:value', `${year}-${month}-${day}`);
};

const setErrors = () => {
  const errors = [];

  if (inputRef.value?.validity.badInput) {
    errors.push('i18n_error_invalid_date');
  }

  emit('update:errors', errors);
};
</script>

<style scoped lang="scss">
.date {
  position: relative;
}
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.input {
  -webkit-appearance: textfield;
  -moz-appearance: textwidget;

  &::-webkit-date-and-time-value {
    text-align: left;
    min-height: 1.25rem;
  }
}
</style>
