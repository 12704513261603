<template>
  <div class="custom-input" style="height: 100%; width: 100%">
    <widgets-label :label="userProps.label" :required="false" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <iframe
      v-if="base64HTML"
      :id="iframeId"
      ref="iframe"
      :src="`data:text/html;base64,${base64HTML}`"
      :style="style"
    />
  </div>
</template>

<script lang="ts" setup>
import { isEqual } from 'lodash';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'custom-input'>;
  errors: string[];
  value: WidgetProps<'custom-input'>['value'];
}>();

const style = {
  width: '100%',
  height: props.userProps.height ? `${props.userProps.height}px` : '100%',
  border: 'none',
};

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'custom-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
  (e: 'custom-event', value: unknown): void;
}>();

const iframe = ref<HTMLIFrameElement>();
const iframeId = Math.random().toString(36).slice(2);
const base64HTML = ref<string>('');

type IframeMessage = {
  type: string;
  id: string;
  payload: unknown;
};

const isIframeMessage = (userProps: unknown | IframeMessage): boolean => {
  return !!(
    typeof userProps === 'object' &&
    userProps !== null &&
    'type' in userProps &&
    'id' in userProps &&
    userProps.id === iframeId
  );
};

const handleMessage = (event: MessageEvent) => {
  if (!isIframeMessage(event.data)) return;

  if (event.data.type === 'change') {
    emit('update:value', event.data.payload);
  } else {
    emit(event.data.type, event.data.payload);
  }
};

onMounted(() => {
  window.addEventListener('message', handleMessage);
  updateHTML();
});

onBeforeUnmount(() => {
  window.removeEventListener('message', handleMessage);
});

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) {
      return;
    }
    updateHTML();
  },
);

watch(
  () => props.userProps.value,
  (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) return;
    updateHTML();
  },
);

watch(
  () =>
    `${props.userProps.htmlHead}${props.userProps.htmlBody}${props.userProps.css}${props.userProps.js}`,
  () => updateHTML(),
);

const updateHTML = () => {
  const SCRIPT = 'script';
  const value = props.value ?? props.userProps.value ?? '';
  const { htmlHead, htmlBody, css, js } = props.userProps;

  base64HTML.value = window.btoa(`
    <!DOCTYPE html>
    <html>
      <head>
        <meta charset="utf-8">
        <title>Custom Widget</title>
        ${htmlHead ?? ''}
        <style>
          ${css ?? ''}
        </style>
      </head>
      <body>
        ${htmlBody ?? ''}
        <${SCRIPT}>
          window.value = ${JSON.stringify(value || null)};

          function customEvent(payload) {
            const id = "${iframeId}";
            window.parent.postMessage({ type: "custom-event", id, payload }, "*");
          }
          function changeEvent(payload) {
            const id = "${iframeId}";
            window.parent.postMessage({ type: "change", id, payload }, "*");
          }
        </${SCRIPT}>
        <${SCRIPT}>
          ${js ?? ''}
        </${SCRIPT}>
      </body>
    </html>
  `);
};
</script>
