<template>
  <ant-table
    :data-source="rowsMetadata"
    :columns="columnsMetadata"
    :pagination="{
      position: ['bottomCenter'],
      defaultPageSize: props.rowsPerPage,
      showSizeChanger: !props.rowsPerPage,
    }"
    :row-selection="getSelectionConfig"
    :scroll="{
      x: props.columns.length * 200,
    }"
  >
    <template
      v-if="props.enableSearch"
      #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
    >
      <div style="padding: 8px">
        <ant-input
          ref="searchInput"
          :placeholder="`Search ${column.dataIndex}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
              (e: any) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
          @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
        />
        <ant-button
          type="primary"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          <template #icon><SearchOutlined /></template>
          Search
        </ant-button>
        <ant-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
          Reset
        </ant-button>
      </div>
    </template>
    <template v-if="props.enableSearch">
      <search-outlined :two-tone-color="color" />
    </template>
    <template #bodyCell="{ column, text, record: row }">
      <template v-if="props.columns.map((c) => c.key).includes(column.dataIndex as string)">
        <div :onclick="() => handleRowClick(row)">
          <ant-input
            v-if="editingRows[row.index]"
            v-model:value="editingRows[row.index][column.dataIndex as string]"
            style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'buttons'">
        <div class="buttons">
          <ant-button v-if="editingRows[row.index]" type="text" @click="handleSaveEdit(row)">
            <save-two-tone :two-tone-color="color" />
          </ant-button>
          <ant-popconfirm
            v-if="editingRows[row.index]"
            title="Sure to cancel?"
            @confirm="handleCancelEdit(row)"
          >
            <ant-button type="text"> <close-circle-two-tone :two-tone-color="color" /></ant-button>
          </ant-popconfirm>
          <ant-button
            v-if="props.editable && !editingRows[row.index]"
            type="text"
            @click="handleEditStart(row)"
          >
            <edit-two-tone :two-tone-color="color" />
          </ant-button>
          <ant-dropdown v-if="props.actions && !editingRows[row.index]" trigger="click">
            <template #overlay>
              <ant-menu>
                <ant-menu-item
                  v-for="action in props.actions"
                  :key="action"
                  type="text"
                  @click="handleActionClick(action, row)"
                >
                  {{ action }}
                </ant-menu-item>
              </ant-menu>
            </template>
            <ant-button type="text">
              <menu-outlined :style="{ color: color }" />
            </ant-button>
          </ant-dropdown>
        </div>
      </template>
      <template v-else>
        <span v-if="state.searchText && state.searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${state.searchText})|(?=${state.searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === state.searchText.toLowerCase()"
              :key="i"
              class="highlight"
            >
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
      </template>
    </template>
  </ant-table>
</template>

<script setup lang="ts">
import {
  CloseCircleTwoTone,
  EditTwoTone,
  MenuOutlined,
  SaveTwoTone,
  SearchOutlined,
} from '@ant-design/icons-vue';
import { ComputedRef, computed, nextTick, reactive, ref } from 'vue';

import {
  Button as AntButton,
  Dropdown as AntDropdown,
  Input as AntInput,
  Menu as AntMenu,
  MenuItem as AntMenuItem,
  Popconfirm as AntPopconfirm,
  Table as AntTable,
  type TableColumnType as AntTableColumnType,
} from 'ant-design-vue';
import { RowSelectionType, TableRowSelection } from 'ant-design-vue/es/table/interface';

export type ATableRow = Record<string, any>;
export type ATableColumn = { title: string; key: string };

const props = defineProps<{
  data: ATableRow[];
  columns: ATableColumn[];
  enableSearch?: boolean;
  editable?: boolean;
  mainColor?: string;
  actions?: string[];
  rowsPerPage?: number;
  selectedIndexes?: number[];
  selectable?: 'multiple' | 'single' | null;
  selectionDisabled?: boolean;
}>();

const emit = defineEmits<{
  (e: 'rowEdit', payload: { oldRow: ATableRow; newRow: ATableRow }): void;
  (e: 'actionClick', payload: { action: string; row: ATableRow }): void;
  (e: 'rowClick', payload: { row: ATableRow }): void;
  (e: 'update:selectedIndexes', selectedIndexes: number[]): void;
}>();

const color = computed(() => props.mainColor ?? '#D14056');

const state = reactive({
  searchText: '',
  searchedColumn: '',
});

const searchInput = ref();

const rowsMetadata: ComputedRef<ATableRow[]> = computed(() => {
  const result = props.data.map((row, index) => ({
    ...row,
    key: index,
  }));

  return result;
});

const columnsMetadata: ComputedRef<AntTableColumnType<ATableRow>[]> = computed(() => {
  const result = props.columns.map(
    (col, index): AntTableColumnType<ATableRow> => ({
      title: col.title,
      dataIndex: col.key,
      key: col.key,
      customFilterDropdown: true,
      sorter: {
        compare: (a, b) => {
          if (typeof a[col.key] === 'number' && typeof b[col.key] === 'number') {
            return (a[col.key] as number) - (b[col.key] as number);
          }
          if (typeof a[col.key] === 'string' && typeof b[col.key] === 'string') {
            return (a[col.key] as string).localeCompare(b[col.key] as string);
          }
          return 1;
        },
        multiple: index,
      },
      onFilter: (value, record) => {
        return record[col.key].toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      onFilterDropdownOpenChange: (visible): void => {
        if (visible) {
          nextTick().then(() => {
            searchInput.value.focus();
          });
        }
      },
    }),
  );

  if (props.editable || props.actions) {
    const editableSize = props.editable ? 80 : 0;
    const actionsSize = props.actions ? 40 : 0;

    result.push({
      title: '',
      dataIndex: 'buttons',
      width: editableSize + actionsSize,
      fixed: 'right',
      align: 'center',
    });
  }

  return result;
});

const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
  confirm();
  state.searchText = selectedKeys[0];
  state.searchedColumn = dataIndex;
};

const handleReset = (clearFilters: ({ confirm }: { confirm: boolean }) => void) => {
  clearFilters({
    confirm: true,
  });
  state.searchText = '';
};

const editingRows: Record<string, ATableRow> = reactive({});

const handleRowClick = (row: ATableRow) => {
  emit('rowClick', { row });
};

const handleActionClick = (action: string, row: ATableRow) => {
  emit('actionClick', { action, row });
};

const handleEditStart = (row: ATableRow) => {
  editingRows[row.index] = { ...row };
};

const handleSaveEdit = (row: ATableRow) => {
  const match = props.data.filter((r) => r.index === row.index);

  emit('rowEdit', {
    oldRow: match[0],
    newRow: editingRows[row.index],
  });

  delete editingRows[row.index];
};

const handleCancelEdit = (row: ATableRow) => {
  delete editingRows[row.index];
};

const selectedRowIndexes = ref<number[]>([]);

const getSelectionConfig = computed<TableRowSelection<ATableRow> | undefined>(() => {
  if (!props.selectable) return undefined;

  const selectableMap: Record<string, RowSelectionType> = {
    multiple: 'checkbox',
    single: 'radio',
  };

  return {
    type: selectableMap[props.selectable],
    selectedRowKeys: props.selectedIndexes,
    onChange: (newSelectedRowIndexes: (number | string)[], _selectedRows: any[]) => {
      selectedRowIndexes.value = newSelectedRowIndexes.map((i) => Number(i));
      emit(
        'update:selectedIndexes',
        newSelectedRowIndexes.map((i) => Number(i)),
      );
    },
    getCheckboxProps: (row: ATableRow) => ({
      disabled: props.selectionDisabled,
      name: row[props.columns[0].key],
    }),
  };
});
</script>

<style scoped>
.ant-table {
  width: 100%;
  height: 100%;
}
.highlight {
  padding: 0px;
}

.buttons {
  display: flex;

  &:deep() {
    .ant-btn {
      padding: 2px 4px;
    }
  }
}

.editable-buttons {
  margin-right: 8px;
}
</style>
