<template>
  <div class="dropdown-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <Autocomplete
      v-model="autoCompleteValue"
      force-selection
      :pt="{
        input: {
          id: `dropdown-input-${uniqueId}`,
        },
      }"
      append-to="self"
      dropdown
      :suggestions="filteredOptionLabels"
      :multiple="userProps.multiple"
      :placeholder="userProps.placeholder"
      :disabled="userProps.disabled"
      @change="handleChange"
      @complete="filterOptions"
    >
      <template #option="slotProps">
        <div>
          <span>{{ slotProps.option }}</span>
          <ph-check v-if="isSelected(slotProps.option)" color="#8692a5" />
        </div>
      </template>
    </Autocomplete>
  </div>
</template>
<script lang="ts" setup>
import { PhCheck } from '@phosphor-icons/vue';
import { isArray } from 'lodash';
import Autocomplete, {
  AutoCompleteChangeEvent,
  type AutoCompleteCompleteEvent,
} from 'primevue/autocomplete';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

type Option = { label: string; value: string };
type VuePrimeAutocompleteValue = unknown[] | unknown | null;

const props = defineProps<{
  userProps: WidgetProps<'dropdown-input'>;
  errors: string[];
  value: WidgetProps<'dropdown-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'dropdown-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const uniqueId = ref(Math.random().toString(36).substring(7));

const autoCompleteValue = ref<VuePrimeAutocompleteValue>(parseValue(props.userProps.value));

function toArray<T>(value: T | T[] | null | undefined): T[] {
  if (value == null) return [];
  if (isArray(value)) return value;
  return [value];
}

function isEqual<T>(a: T, b: T) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function parseValue(value: WidgetProps<'dropdown-input'>['value']): VuePrimeAutocompleteValue {
  const selectedOptions = value.map((v) => props.userProps.options.find((o) => o.value === v));

  if (props.userProps.multiple) {
    return selectedOptions.map((o) => o?.label).filter(Boolean);
  }

  return selectedOptions.length ? selectedOptions[0]?.label : null;
}

function serializeValue(value: VuePrimeAutocompleteValue) {
  const selectedOptions = props.userProps.options.filter((o) => toArray(value).includes(o.label));
  const selectedValues = selectedOptions.map((o) => o.value);
  return Array.from(new Set(selectedValues));
}

function handleChange(e: AutoCompleteChangeEvent) {
  emit('update:value', serializeValue(e.value));
}

const optionsToLabels = (options: Option[]) => {
  return options.map((o) => o.label);
};

const filteredOptionLabels = ref<string[]>(optionsToLabels(props.userProps.options));

const filterOptions = (e: AutoCompleteCompleteEvent) => {
  filteredOptionLabels.value = props.userProps.options
    .map((o) => o.label)
    .filter((label) => label.toLowerCase().includes(e.query.toLowerCase()));
};

const isSelected = (label: string) => {
  return toArray(autoCompleteValue.value).includes(label);
};

watch(props.userProps.value, () => {
  if (!isEqual(props.userProps.value, serializeValue(autoCompleteValue.value))) {
    autoCompleteValue.value = parseValue(props.userProps.value);
  }
});

watch(
  () => props.value,
  () => {
    if (!isEqual(props.value, serializeValue(autoCompleteValue.value))) {
      autoCompleteValue.value = parseValue(props.value);
    }
  },
);

watch(props.userProps.options, () => {
  filteredOptionLabels.value = optionsToLabels(props.userProps.options);
});
</script>

<style lang="scss" scoped>
@import '../../common/style/widgets.scss';

@include autocomplete;

.dropdown-input {
  display: flex;
  flex-direction: column;
}
</style>
