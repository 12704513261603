import type { UploadFile } from 'ant-design-vue';
import { Component, ref, Ref } from 'vue';

import {
  PhCode,
  PhFileArchive,
  PhFileCloud,
  PhFileDoc,
  PhFilePdf,
  PhFilePpt,
  PhFileText,
  PhFileXls,
  PhImage,
  PhMonitorPlay,
  PhWaveform,
} from '@phosphor-icons/vue';

const Previewables = ['Text', 'Image', 'Code', 'PDF', 'Video', 'Audio'] as const;
const NonPreviewables = [
  'Unknown',
  'Spreadsheet',
  'Document',
  'Presentation',
  'Archive',
  'Code',
] as const;
const FileTypes = [...Previewables, ...NonPreviewables] as const;

type PreviewableType = typeof Previewables[number];
type NonPreviewableType = typeof NonPreviewables[number];

type FileType = typeof FileTypes[number];

function isPreviewable(type: FileType): type is PreviewableType {
  return (Previewables as readonly string[]).includes(type);
}

const PreviewIcons: Record<FileType, Component> = {
  Text: PhFileText,
  Image: PhImage,
  Code: PhCode,
  PDF: PhFilePdf,
  Video: PhMonitorPlay,
  Audio: PhWaveform,
  Archive: PhFileArchive,
  Document: PhFileDoc,
  Presentation: PhFilePpt,
  Spreadsheet: PhFileXls,
  Unknown: PhFileCloud,
};

type State = {
  open: boolean;
  file: UploadFile;
};

export class PreviewController {
  public state: Ref<State>;

  constructor() {
    this.state = ref({
      open: false,
      file: {
        uid: '',
        name: '',
        status: 'done',
        response: [],
        url: '',
        type: '',
        size: 0,
      },
    });
  }

  open = (file: UploadFile) => {
    this.state.value = {
      open: true,
      file,
    };
  };

  close = () => {
    this.state.value.open = false;
  };

  hasPreview = (file: UploadFile) => {
    const fileType = this.typeof(file.type);
    if (!isPreviewable(fileType)) {
      return false;
    }

    switch (fileType) {
      case 'Text':
      case 'Image':
      case 'Code':
        return true;

      case 'PDF':
        return window.navigator.pdfViewerEnabled;

      case 'Video':
        const videoElement = document.createElement('video');
        return videoElement.canPlayType(file.type || '') !== '';

      case 'Audio':
        const audioElement = document.createElement('audio');
        return audioElement.canPlayType(file.type || '') !== '';
    }
  };

  fileName = (file: UploadFile) => {
    return file?.name || '';
  };

  fileSrc = (file: UploadFile) => {
    return '/_files/' + file?.response[0];
  };

  fileThumbnail = (file: UploadFile) => {
    return this.hasPreview(file) ? this.fileSrc(file) : '';
  };

  typeof = (mimeType?: string): FileType => {
    if (!mimeType) {
      return 'Unknown';
    }

    if (mimeType === 'application/pdf') {
      return 'PDF';
    }

    const prefix = mimeType.split('/')[0];
    switch (prefix) {
      case 'audio':
        return 'Audio';
      case 'video':
        return 'Video';
      case 'image':
        return 'Image';
    }

    if (
      mimeType.includes('spreadsheet') ||
      mimeType.includes('excel') ||
      mimeType.includes('.sheet')
    ) {
      return 'Spreadsheet';
    }

    if (mimeType.includes('.document')) {
      return 'Document';
    }

    if (mimeType.includes('.presentation')) {
      return 'Presentation';
    }

    switch (mimeType) {
      case 'text/plain':
      case 'text/markdown':
      case 'text/csv':
        return 'Text';
      case 'application/zip':
      case 'application/vnd.rar':
      case 'application/x-7z-compressed':
      case 'application/x-tar':
      case 'application/gzip':
        return 'Archive';
      case 'text/html':
      case 'text/css':
      case 'text/x-python-script':
      case 'application/javascript':
      case 'application/typescript':
      case 'application/json':
      case 'application/xml':
      case 'application/x-yaml':
      case 'application/toml':
        return 'Code';
    }
    return 'Unknown';
  };

  handlePreview = (file: UploadFile) => {
    if (this.hasPreview(file)) this.open(file);
  };

  fetchTextContent = async (file: UploadFile) => {
    const src = this.fileSrc(file);
    const content = await window.fetch(src);
    return await content.text();
  };

  iconPreview = (file: UploadFile) => {
    const fileType = this.typeof(file.type);
    return PreviewIcons[fileType];
  };
}
