export class Toast {
  static toastContainer: HTMLElement | null = null;

  // Initialization
  static init() {
    Toast.createContainer();
    Toast.addStylesheetToDocument();
  }

  private static createContainer() {
    Toast.toastContainer = document.createElement('div');
    Object.assign(Toast.toastContainer.style, toastContainerStyle);
    document.body.appendChild(Toast.toastContainer);
  }

  private static addStylesheetToDocument() {
    const sheet = document.createElement('style');
    sheet.innerHTML = stylesheet;
    document.head.appendChild(sheet);
  }

  static info(message: string, ttl = 10000) {
    Toast.showMessage(message, ttl);
  }

  static error(message: string, ttl = 10000) {
    Toast.showMessage(message, ttl, {
      'background-color': '#ffaaaa',
      'font-family': 'monospace',
      color: '#000000',
    });
  }

  private static showMessage(message: string, ttl: number, style: { [key: string]: string } = {}) {
    if (!Toast.toastContainer) throw new Error('Toast not initialized');
    if (!message.trim()) return;
    const toastMessageElement = Toast.makeToastMessageElement(message);
    Object.assign(toastMessageElement.style, style);
    Toast.toastContainer.appendChild(toastMessageElement);
    setTimeout(() => toastMessageElement.remove(), ttl);
  }

  private static makeToastMessageElement(message: string) {
    const toastMessage = document.createElement('div');
    toastMessage.innerHTML = message;
    toastMessage.classList.add('abstra-toast-message');
    toastMessage.onclick = toastMessage.remove;
    return toastMessage;
  }
}

const toastContainerStyle = {
  position: 'fixed',
  bottom: '10px',
  right: '0',
  left: '0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const stylesheet = `
.abstra-toast-message {
  padding: 15px;
  margin-top: 5px;
  border-radius: 0.5rem;
  background-color: #333333;
  opacity: 0.8;
  font-size: 20px;
  color: #ffffff;
  cursor: default;
  user-select: none;
  position: relative;
}

.abstra-toast-message:hover {
  opacity: 1
}`;
