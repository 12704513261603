const translation: { [key: string]: RegExp } = {
  '0': /\d/,
  a: /[a-zA-Z]/,
  '*': /./,
};

export function getMaskedValue(initialPattern: string, str: string): string {
  if (initialPattern.length === 0 || str.length === 0) return '';

  const pattern = patternSelected(initialPattern, str);
  const patternChar = pattern[0];
  const regex = translation[patternChar];

  if (!regex) {
    return (
      patternChar +
      getMaskedValue(pattern.slice(1), str.startsWith(patternChar) ? str.slice(1) : str)
    );
  }

  const match = str.match(regex);

  if (!match) return '';

  return match[0] + getMaskedValue(pattern.slice(1), str.slice((match.index ?? 0) + 1));
}

export function checkMaskSize(pattern: string, str: string): boolean {
  if (pattern.includes('|')) {
    const masks = sort(pattern);
    return masks.some((mask) => mask.length == str.length);
  }

  return str.length === pattern.length;
}

function patternSelected(pattern: string, str: string): string {
  if (!pattern.includes('|')) return pattern;

  const masks = sort(pattern);

  for (const mask of masks) {
    if (str.replace(/\D/g, '').length <= mask.replace(/\D/g, '').length) {
      return mask;
    }
  }
  return masks[0];
}

function sort(pattern: string) {
  const masks = pattern.split('|');
  return masks.sort((a, b) => a.length - b.length);
}
