<template>
  <div class="phone-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <div class="flex">
      <input
        :value="internalCodeValue"
        class="select"
        maxlength="4"
        :class="['input', errors.length && 'error']"
        placeholder="+1"
        :disabled="userProps.disabled"
        @input="handleCodeInput"
        @change="handleCodeChange"
        @blur="updateErrors"
      />
      <input
        :value="internalNumberValue"
        :class="['input', errors.length && 'error']"
        :disabled="userProps.disabled || !getNumberMask(internalCodeValue)"
        :placeholder="numberPlaceholder"
        @blur="updateErrors"
        @input="handleNumberInput"
        @change="updateErrors"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { CountryCodeMask, countryCodeMasks } from '../../common/countryCodeMask';
import { checkMaskSize, getMaskedValue } from '../../common/mask';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'phone-input'>;
  errors: string[];
  value: WidgetProps<'phone-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'phone-input'>['value']): void;
}>();

const numberPlaceholder = computed(
  () => props.userProps.placeholder || getNumberMask(internalCodeValue.value)?.placeholder || '',
);

const internalNumberValue = ref(maskNumber(props.value.nationalNumber, props.value.countryCode));
const internalCodeValue = ref(maskCode(props.value.countryCode));

function digits(value: string) {
  return value.replace(/\D/g, '');
}

function getNumberMask(code: string): CountryCodeMask | null {
  return countryCodeMasks.find((c) => c.code === digits(code)) ?? null;
}

function maskNumber(value: string, code: string) {
  const mask = getNumberMask(code)?.mask;
  if (!mask) return '';
  return getMaskedValue(mask, value);
}

function maskCode(value: string) {
  return getMaskedValue('+000', value);
}

function emitValue() {
  emit('update:value', {
    countryCode: digits(internalCodeValue.value),
    nationalNumber: internalNumberValue.value,
  });
}

const handleCodeChange = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  internalCodeValue.value = maskCode(value);
};

const handleCodeInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  internalCodeValue.value = maskCode(value);
};

const handleNumberInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  internalNumberValue.value = maskNumber(value, internalCodeValue.value);
  emitValue();
};

const updateErrors = () => {
  if (!props.userProps.required && !internalNumberValue.value && !internalCodeValue.value) {
    emit('update:errors', []);
    return;
  }

  const errors: string[] = [];
  const mask = getNumberMask(internalCodeValue.value)?.mask;

  if (!mask) {
    emit('update:errors', ['i18n_error_invalid_country_code']);
    return;
  }

  const isValid = checkMaskSize(mask, internalNumberValue.value);

  if (!isValid) {
    errors.push(props.userProps.invalidMessage);
  }

  emit('update:errors', errors);
};

watchEffect(() => {
  internalNumberValue.value = maskNumber(props.value.nationalNumber, props.value.countryCode);
  internalCodeValue.value = maskCode(props.value.countryCode);
});
</script>

<style scoped lang="scss">
@import '../../common/style/widgets.scss';

.select {
  flex-basis: 60px;
  flex-grow: 1;
  flex-shrink: 0;
  text-align: center;
}

.flex {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}
</style>
