<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <div :class="{ hidden: !ctrl.state.value.isPreviewing }" class="preview-container">
    <video ref="previewRef" class="preview"></video>
    <div class="icon-container cancel" @click="ctrl.stopStream">
      <ph-x />
    </div>
    <div class="controls">
      <div
        v-if="ctrl.state.value.hasMultipleDevices"
        class="icon-container change-camera"
        @click="ctrl.changeDevice"
      >
        <ph-camera-rotate />
      </div>
      <div class="icon-container pause" @click="ctrl.toggle">
        <ph-play v-if="ctrl.state.value.isPreviewPaused" />
        <ph-pause v-else />
      </div>
      <div class="icon-container take-picture" @click="ctrl.takePicture">
        <ph-aperture />
      </div>
    </div>
  </div>
  <card-list
    ref="cardListRef"
    :accepted-formats="['image/*']"
    accepted-mime-types="image/*"
    list-type="picture-card"
    :capture="ctrl.capture"
    :disabled="userProps.disabled"
    :value="ctrl.initialValue"
    :errors="errors"
    :multiple="ctrl.multiple"
    :locale="locale"
    @update:value="ctrl.onChangeHandler"
  >
    <template #ui>
      <div class="ui-container" @click="ctrl.openPreview">
        <div class="icon-container">
          <ph-camera />
        </div>
      </div>
    </template>
  </card-list>
</template>

<script lang="ts" setup>
import { PhAperture, PhCamera, PhCameraRotate, PhPause, PhPlay, PhX } from '@phosphor-icons/vue';
import { onMounted, onUnmounted, ref } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import CardList from '../../common/components/upload/CardList.vue';
import { SupportedLocales } from '../../common/i18n';
import type { WidgetProps } from '../../generated/widgetTypes';
import { CaptureController } from './controller';

const props = defineProps<{
  userProps: WidgetProps<'camera-input'>;
  errors: string[];
  value: WidgetProps<'camera-input'>['value'];
  locale: SupportedLocales;
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'camera-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const previewRef = ref<HTMLVideoElement | null>(null);
const cardListRef = ref<InstanceType<typeof CardList> | null>(null);

const ctrl = new CaptureController(props.userProps, emit, previewRef, cardListRef);

onMounted(async () => {
  ctrl.loadDevices();
});

onUnmounted(() => {
  ctrl.stopStream();
});
</script>

<style scoped>
@import '../../common/style/widgets.scss';

.hidden {
  display: none;
}

.preview-container {
  position: relative;
  width: 100%;
  height: auto;
}

.preview {
  width: 100%;
  height: auto;
}

.ui-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-container {
  font-size: 20px;
  width: 30px; /* Assuming a fixed size for the icons */
  height: 30px; /* Adjust as necessary */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  background-color: var(--color-main-light);
  border-radius: 50%;
}

.icon-container svg {
  transition: filter 0.3s ease, transform 0.3s ease;
}

.icon-container:hover svg {
  filter: brightness(1.2) contrast(1.1);
  transform: scale(1.2);
}

.icon-container.cancel {
  position: absolute;
  top: 0px;
  right: 0px;
}

.icon-container.take-picture {
  font-size: 30px;
  width: 45px;
  height: 45px;
}

.controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 0px;
}
</style>
