const ExtensionToMimeMap: Record<string, string> = {
  txt: 'text/plain',
  md: 'text/markdown',
  csv: 'text/csv',
  html: 'text/html',
  css: 'text/css',
  py: 'text/x-python-script',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  mp3: 'audio/mp3',
  wav: 'audio/wav',
  flac: 'audio/flac',
  aac: 'audio/aac',
  ogg: 'audio/ogg',
  wma: 'audio/x-ms-wma',
  avi: 'video/avi',
  mp4: 'video/mp4',
  mkv: 'video/x-matroska',
  mov: 'video/quicktime',
  webm: 'video/webm',
  flv: 'video/x-flv',
  wmv: 'video/x-ms-wmv',
  m4v: 'video/x-m4v',
  zip: 'application/zip',
  rar: 'application/vnd.rar',
  '7z': 'application/x-7z-compressed',
  tar: 'application/x-tar',
  gzip: 'application/gzip',
  js: 'application/javascript',
  ts: 'application/typescript',
  json: 'application/json',
  xml: 'application/xml',
  yaml: 'application/x-yaml',
  toml: 'application/toml',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  doc: 'application/msword',
  ppt: 'application/vnd.ms-powerpoint',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  image: 'image/*',
  video: 'video/*',
  audio: 'audio/*',
  text: 'text/*',
  application: 'application/*',
  unknown: '*',
};

import type { UploadChangeParam, UploadFile } from 'ant-design-vue';
import { Upload } from 'ant-design-vue';
import { UploadListProgressProps } from 'ant-design-vue/es/upload/interface';
import type { WidgetProps } from '../../../generated/widgetTypes';
import { SupportedLocales, i18nProvider } from '../../i18n';

type EmitEvents = {
  (e: 'update:value', value: WidgetProps<'file-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
};

const extensionToMime = (extension: string) => {
  const extensionWithoutDot = extension?.replace('.', '');
  if (extensionWithoutDot in ExtensionToMimeMap) {
    return ExtensionToMimeMap[extensionWithoutDot];
  }
  return ExtensionToMimeMap.unknown;
};

export class UploadController {
  constructor(
    private emit: EmitEvents,
    private value: WidgetProps<'file-input'>['value'],
    private thumbnail: (file: UploadFile) => string,
    private locale: SupportedLocales,
    private acceptedFormats?: string[],
    private acceptedMimeTypes?: string,
    private maxFileSize?: number | null,
    private multiple?: boolean,
  ) {}

  initialFileList = (): UploadFile[] => {
    return this.value.map((url) => {
      const file = new File([], url);
      return {
        uid: url,
        name: url.split('/').pop() || url,
        status: 'done',
        response: [url.replace('/_files/', '')],
        url,
        type: extensionToMime(url.split('.').pop()),
        size: file.size,
      };
    });
  };

  private emitValue = (value: string[]) => {
    this.value = value;
    this.emit('update:value', value);
  };

  private emitErrors = (errors: string[]) => {
    this.emit('update:errors', errors);
  };

  get accept() {
    return this.acceptedMimeTypes || '*';
  }

  get action() {
    return '/_files/';
  }

  get method(): 'PUT' {
    return 'PUT';
  }

  get headers() {
    return { cache: 'no-cache', mode: 'cors' };
  }

  get progress(): UploadListProgressProps {
    return {
      strokeWidth: 5,
      showInfo: true,
      format: (percent?: number) => `${percent?.toFixed(0) || 0}%`,
    };
  }

  get maxCount() {
    return this.multiple ? undefined : 1;
  }

  handleReject = () => {
    const formats = this.acceptedFormats?.join(', ') || '*';
    this.emitErrors([
      i18nProvider.translate('i18n_upload_area_rejected_file_extension', this.locale, { formats }),
    ]);
  };

  customData = (file: UploadFile) => {
    return {
      filename: file.name,
    };
  };

  beforeUpload = (file: UploadFile) => {
    if (this.maxFileSize && file.size && file.size / 1024 / 1024 > this.maxFileSize) {
      this.emitErrors([
        i18nProvider.translate('i18n_upload_max_size_excided', this.locale, {
          fileName: file.name,
          maxSize: this.maxFileSize,
        }),
      ]);
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  handleChange = (event: UploadChangeParam) => {
    if (event.file.status === 'done') {
      event.file.thumbUrl = this.thumbnail(event.file);
    }
    const doneFiles = event.fileList.filter((file) => file.status === 'done');
    const fileNames = doneFiles.map((file) => '/_files/' + file.response[0]) || [];
    this.emitValue(fileNames);

    if (event.file.status === 'error') {
      this.emitErrors([
        i18nProvider.translate('i18n_upload_failed', this.locale, { fileName: event.file.name }),
      ]);
    }
  };
}
