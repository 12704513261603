<template>
  <div class="currency-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <div class="input-wrapper">
      <div :class="['symbol', { focused: isFocused }]">
        {{ currencySymbol }}
      </div>
      <input
        ref="input"
        :style="{ paddingLeft: currencySymbolPadding }"
        :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
        :disabled="userProps.disabled"
        :placeholder="userProps.placeholder"
        @input="handleInput"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const input = ref<HTMLInputElement>();

const props = defineProps<{
  userProps: WidgetProps<'currency-input'>;
  errors: string[];
  value: WidgetProps<'currency-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'currency-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const isFocused = ref(false);

const getOnlyDigits = (value: string) => {
  return value.replace(/[^0-9]/g, '');
};

const handleInput = (e: Event) => {
  const inputValue = (e.target as HTMLInputElement).value;

  const digitsString = getOnlyDigits(inputValue);
  const floatValue = digitsToFloat(digitsString);
  const formattedString = formatCurrency(floatValue);

  input.value!.value = formattedString;

  if (floatValue === props.value) return;

  emit('update:value', floatValue);
};

const formatCurrency = (floatValue: number | null): string => {
  if (floatValue === null) return '';

  return new Intl.NumberFormat(navigator.language, {
    style: 'decimal',
    minimumFractionDigits: 2,
  }).format(floatValue);
};

function digitsToFloat(digitsString: string): number | null {
  if (!digitsString) return null;
  return parseInt(digitsString) / 100;
}

const currencySymbol = computed<string>(() => {
  return Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    currency: props.userProps.currency,
    style: 'currency',
    currencyDisplay: 'symbol',
  })
    .format(0)
    .replace('0', '');
});

const currencySymbolPadding = computed<string>(() => currencySymbol.value.length * 30 + 'px');

onMounted(() => {
  input.value!.value = formatCurrency(props.value);
});

watch(
  () => props.value,
  () => {
    input.value!.value = formatCurrency(props.value);
  },
);
</script>

<style scoped lang="scss">
.input-wrapper {
  position: relative;

  .symbol {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #343b46;
    position: absolute;
    font-size: 1rem;
    left: 1rem;
    top: 0px;
    bottom: 0px;
  }
}
</style>
