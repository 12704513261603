<template>
  <ant-modal
    v-if="ctrl.state.value.open"
    :open="true"
    :title="state.filename"
    @cancel="ctrl.close"
    style="max-width: 80dvw; min-width: 40dvw; height: auto"
  >
    <div v-if="!state.hasPreview">
      <p>Unsupported file type</p>
    </div>
    <img v-else-if="state.previewType === 'Image'" style="width: 100%" :src="state.src" />
    <video
      v-else-if="state.previewType === 'Video'"
      style="width: 100%; height: auto"
      controls
      preload="metadata"
    >
      <source :src="state.src" :type="state.file.type" />
    </video>
    <audio
      v-else-if="state.previewType === 'Audio'"
      style="width: 100%"
      controls
      preload="metadata"
    >
      <source :src="state.src" :type="state.file.type" />
    </audio>
    <embed
      v-else-if="state.previewType === 'PDF'"
      style="width: 100%; min-height: 60dvh"
      :src="state.src"
      :type="state.file.type"
    />
    <div v-else-if="state.previewType === 'Text' || state.previewType === 'Code'">
      <div ref="editor" style="width: 100%; min-height: 40dvh"></div>
    </div>
    <template #footer> </template>
  </ant-modal>
</template>

<script lang="ts" setup>
import { Modal as AntModal, UploadFile } from 'ant-design-vue';
import { ref, watch } from 'vue';
import type { PreviewController } from './previewController';

const props = defineProps<{
  ctrl: PreviewController;
}>();

const state = ref<{
  hasPreview: boolean;
  filename: string;
  src: string;
  previewType: string;
  file: UploadFile;
}>({
  hasPreview: false,
  filename: '',
  src: '',
  previewType: '',
  file: props.ctrl.state.value.file,
});

const editor = ref<HTMLDivElement>();

watch(props.ctrl.state, async () => {
  const file = props.ctrl.state.value.file;
  state.value.file = file;
  state.value.hasPreview = props.ctrl.hasPreview(file);
  state.value.filename = props.ctrl.fileName(file);
  state.value.src = props.ctrl.fileSrc(file);
  state.value.previewType = props.ctrl.typeof(file.type);

  if (state.value.previewType === 'Code' || state.value.previewType === 'Text') {
    const content = await props.ctrl.fetchTextContent(file);
    initEditor(editor.value!, content);
  }
});

const initEditor = async (element: HTMLElement, content: string, language?: string) => {
  const monaco = await import('monaco-editor');

  monaco.editor.create(element, {
    language,
    value: content,
    minimap: {
      enabled: false,
    },
    readOnly: true,
    contextmenu: false,
    automaticLayout: true,
    tabSize: 4,
    renderWhitespace: 'none',
    guides: {
      indentation: false,
    },
    theme: 'vs',
    fontFamily: 'monospace',
    lineNumbers: 'on',
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: false,
    renderLineHighlight: 'all',
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
  });
};
</script>
