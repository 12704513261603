<template>
  <div class="number-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      v-model="internalValue"
      :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      type="number"
      :placeholder="userProps.placeholder"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'number-input'>;
  errors: string[];
  value: WidgetProps<'number-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'number-input'>['value']): void;
}>();

const internalValue = ref<string | null>(props.value?.toString() ?? '');

watch(internalValue, (value) => {
  if (value === '') {
    emit('update:value', null);
    return;
  }

  const numValue = Number(value);
  emit('update:value', numValue);
});

watch(
  () => props.value,
  (value) => {
    internalValue.value = value?.toString() || '';
  },
);
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.input {
  -moz-appearance: textwidget;
}
</style>
