<template>
  <div :height="containerHeight" :width="containerWidth">
    <widgets-label ref="label" :label="props.userProps.label" :required="false" />
    <a-table
      :data="userProps.table.data"
      :columns="columns"
      :rows-per-page="pageSize"
      enable-search
      :actions="props.userProps.actions?.length ? props.userProps.actions : undefined"
      @action-click="handleActionClick"
      @row-click="handleRowClick"
      @row-edit="handleRowEdit"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, Ref, watch } from 'vue';
import ATable, { type ATableRow } from '../../common/components/ATable.vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { castValues } from '../../common/utils';
import type { WidgetProps } from '../../generated/widgetTypes';

const DEFAULT_FORM_ROWS_PER_PAGE = 10;

const props = defineProps<{
  userProps: WidgetProps<'pandas-output'>;
  runtime: string;
  containerHeight?: number;
  containerWidth?: number;
}>();

const emit = defineEmits(['row-click', 'action-click', 'row-edit']);

const label: Ref<InstanceType<typeof WidgetsLabel> | null> = ref(null);

const handleActionClick = ({ action, row }: { action: string; row: any }) => {
  emit('action-click', { action, userProps: row });
};

function handleRowClick({ row }: { row: ATableRow }) {
  emit('row-click', { userProps: row.userProps, index: row.index });
}

function handleRowEdit({ oldRow, newRow }: { oldRow: ATableRow; newRow: ATableRow }) {
  const castNewData = castValues(oldRow, newRow);
  emit('row-edit', { old: oldRow, new: castNewData, index: oldRow.index });
}

watch(props.userProps.table.data, () => {});

const columns = computed(() =>
  (props.userProps.displayIndex
    ? props.userProps.table.schema.fields
    : props.userProps.table.schema.fields.filter((f) => f.name != 'index')
  ).map((f) => ({ ...f, title: f.name.toString(), key: f.name.toString() })),
);

const pageSize = computed(() => {
  return props.userProps.pageSize ?? DEFAULT_FORM_ROWS_PER_PAGE;
});
</script>

<style lang="scss" scoped></style>
