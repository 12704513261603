<template>
  <div class="email-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      :value="internalValue"
      :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      :placeholder="userProps.placeholder"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'email-input'>;
  value: WidgetProps<'email-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'email-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const internalValue = ref(props.value);

const handleInput = (event: Event) => {
  const value = (event.target as HTMLInputElement).value;
  internalValue.value = String(value).trim();
  emit('update:value', internalValue.value);
};

watchEffect(() => {
  internalValue.value = props.value;
});
</script>
