<template>
  <widgets-label :label="userProps.label" :required="false" />
  <div ref="root" class="chart"></div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const root = ref<HTMLDivElement | null>(null);
const props = defineProps<{
  userProps: WidgetProps<'plotly-output'> & { layout: any };
}>();

onMounted(async () => {
  plot();
});

const plot = async () => {
  if (!root.value) return;
  const Plotly = await import('plotly.js-dist-min');
  Plotly.newPlot(root.value, props.userProps.figure.data, props.userProps.figure.layout);
};

watch(() => props.userProps.figure, plot, { deep: true });
</script>

<style scoped>
.chart {
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
