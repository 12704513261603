import { Buffer } from 'buffer';

export function loadScript(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

export const castValues = (model: Record<string, any>, dataToCast: Record<string, any>) => {
  const output: Record<string, any> = {};
  Object.keys(model).forEach((key) => {
    const dataType = model[key].constructor.name;
    if (dataType === 'Number') {
      output[key] = Number(dataToCast[key]);
    } else if (dataType === 'Boolean') {
      output[key] = dataToCast[key] === 'true';
    } else if (dataType === 'Date') {
      output[key] = new Date(dataToCast[key]);
    } else if (dataType === 'String') {
      output[key] = dataToCast[key].toString();
    } else if (dataType === 'Object') {
      output[key] = JSON.parse(dataToCast[key]);
    } else if (dataType === 'Array') {
      output[key] = JSON.parse(dataToCast[key]);
    } else if (dataType === 'Null') {
      output[key] = null;
    }
  });
  return output;
};

export const dataUrlToFile = (dataUrl: string, fileName: string): File => {
  const [prefix, payload] = dataUrl.split(',');
  const mimeType = prefix.split(':')[1].split(';')[0];

  const ab = Buffer.from(payload, 'base64').buffer;
  const ia = new Uint8Array(ab);

  return new File([new Blob([ia])], fileName, { type: mimeType });
};
