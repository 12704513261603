<template>
  <ant-checkbox :disabled="userProps.disabled" :checked="isSelected" @click="select">
    <Markdown class="markdown-output" :source="userProps.label" html />
  </ant-checkbox>
</template>
<script lang="ts" setup>
import { Checkbox as AntCheckbox } from 'ant-design-vue';
import { ref, watch } from 'vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'checkbox-input'>;
  errors: string[];
  value: WidgetProps<'checkbox-input'>['value'];
}>();

const isSelected = ref<boolean>(props.value);

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'checkbox-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const select = () => {
  isSelected.value = !isSelected.value;
  emit('update:value', isSelected.value);
};

watch(
  () => props.value,
  () => (isSelected.value = props.value),
);
</script>

<style lang="scss" scoped>
@import '../.../../../widgets-lib/src/common/style/widgets.scss';

.markdown-output {
  :deep() {
    @include link;
    @include code;
  }
}
</style>
