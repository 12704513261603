import { Ref, ref } from 'vue';
import CardList from '../../common/components/upload/CardList.vue';
import { i18nProvider } from '../../common/i18n';
import { dataUrlToFile } from '../../common/utils';
import type { WidgetProps } from '../../generated/widgetTypes';

type EmitEvents = {
  (e: 'update:value', value: WidgetProps<'camera-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
};

type State = {
  isPreviewing: boolean;
  isPreviewPaused: boolean;
  hasMultipleDevices: boolean;
};

export class CaptureController {
  private useCapture: true | undefined;
  private previewRef: Ref<HTMLVideoElement | null>;
  private cardListRef: Ref<InstanceType<typeof CardList> | null>;
  private devices?: MediaDeviceInfo[];
  private selectedDeviceId?: string;
  public state: Ref<State>;

  private stream?: MediaStream;
  private emit: EmitEvents;
  private props: WidgetProps<'camera-input'>;

  constructor(
    props: WidgetProps<'camera-input'>,
    emit: EmitEvents,
    previewRef: Ref<HTMLVideoElement | null>,
    cardListRef: Ref<InstanceType<typeof CardList> | null>,
  ) {
    this.useCapture = CaptureController.hasCaptureSupport();
    this.emit = emit;
    this.previewRef = previewRef;
    this.cardListRef = cardListRef;
    this.props = props;
    this.state = ref({
      isPreviewing: false,
      isPreviewPaused: false,
      hasMultipleDevices: false,
    });
  }

  static hasCaptureSupport() {
    const input = document.createElement('input');
    return input.capture != undefined? true : undefined;
  }

  loadDevices = async () => {
    this.devices = await navigator.mediaDevices.enumerateDevices();
    this.selectedDeviceId = this.devices.find((device) => device.kind === 'videoinput')?.deviceId;
  };

  get initialValue(): string[] {
    return this.props.value || [];
  }

  changeDevice = () => {
    if (!this.devices || !this.selectedDeviceId) return;
    const currentIndex = this.devices.findIndex(
      (device) => device.deviceId === this.selectedDeviceId,
    );
    this.selectedDeviceId = this.devices[(currentIndex + 1) % this.devices.length].deviceId;
    this.startStream();
  };

  get hasMultipleDevices() {
    return (this.devices?.length || 0) > 1;
  }

  get multiple() {
    return this.props.multiple || false;
  }

  startStream = async () => {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.selectedDeviceId,
        },
      });
      if (!this.previewRef.value) throw new Error('Preview is not defined');
      this.previewRef.value.srcObject = this.stream;
      this.play();
    } catch (error) {
      if (error instanceof Error) this.handleStreamError(error);
    }
  };

  openPreview = (e: Event) => {
    //mobile devices will open the native camera app
    if (!this.useCapture) {
      e.preventDefault();
      e.stopPropagation();
      this.startStream();
    }
  };

  get capture() {
    return this.useCapture;
  }

  get mode(): 'waiting' | 'stream-running' | 'stream-paused' {
    if (!this.stream) return 'waiting';
    if (this.state.value.isPreviewPaused) return 'stream-paused';
    return 'stream-running';
  }

  stopStream() {
    if (!this.stream) return;
    this.stream.getTracks().forEach((track) => track.stop());
    this.state.value.isPreviewing = false;
    this.state.value.isPreviewPaused = false;
  }

  toggle() {
    if (!this.state.value.isPreviewing) return;
    if (this.state.value.isPreviewPaused) this.play();
    else this.pause();
  }

  pause() {
    if (!this.previewRef.value) throw new Error('Preview is not defined');
    this.previewRef.value.pause();
    this.state.value.isPreviewing = true;
    this.state.value.isPreviewPaused = true;
  }

  play() {
    if (!this.previewRef.value) throw new Error('Preview is not defined');
    this.previewRef.value.play();
    this.state.value.isPreviewing = true;
    this.state.value.isPreviewPaused = false;
  }

  get isPreviewing() {
    return this.state.value.isPreviewing;
  }

  handleStreamError = (error: Error) => {
    switch (error.name) {
      case 'NotAllowedError':
        this.emit('update:errors', [i18nProvider.translate('i18n_camera_permission_denied')]);
        break;
      case 'NotFoundError':
        this.emit('update:errors', [i18nProvider.translate('i18n_no_camera_found')]);
        break;
      case 'NotReadableError':
        this.emit('update:errors', [i18nProvider.translate('i18n_camera_already_in_use')]);
        break;
      default:
        this.emit('update:errors', [i18nProvider.translate('i18n_permission_error')]);
    }
  };

  public async takePicture() {
    if (!this.previewRef.value) throw new Error('Preview is not defined');
    const canvas = document.createElement('canvas');
    canvas.width = this.previewRef.value.videoWidth;
    canvas.height = this.previewRef.value.videoHeight;
    const context = canvas.getContext('2d');
    if (!context) throw new Error('Canvas context is not defined');
    context.drawImage(this.previewRef.value, 0, 0, canvas.width, canvas.height);
    const data = canvas.toDataURL('image/png');
    this.startUpload(data);
  }

  private startUpload = async (payload: string) => {
    const uploadElement = this.cardListRef.value?.uploadRef?.$el as HTMLElement;
    if (uploadElement) {
      const fileInput = uploadElement.querySelector<HTMLInputElement>('input[type="file"]');
      if (fileInput) {
        const dataTransfer = new DataTransfer();
        const epoch = new Date().getTime();
        dataTransfer.items.add(dataUrlToFile(payload, `camera-${epoch}.png`));
        fileInput.files = dataTransfer.files;
        const event = new Event('change', { bubbles: true });
        fileInput.dispatchEvent(event);
      }
    }
  };

  onChangeHandler = (urls: string[]) => {
    this.emit('update:value', urls);
  };
}
