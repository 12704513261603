import { ZodType, z } from 'zod';
import type { Bucket } from './types';

export class LocalBucket<V extends ZodType, U extends z.infer<V>> implements Bucket<U> {
  private key: string;

  constructor(
    private validator: V,
    private sufix: string,
    private storage: Storage = localStorage,
  ) {
    this.key = `abstra:${this.sufix}`;
  }

  get(): U | null {
    const value = this.storage.getItem(this.key);
    if (value == null) return null;

    try {
      return this.validator.parse(JSON.parse(value));
    } catch (e) {
      return null;
    }
  }

  set(value: U) {
    try {
      this.validator.parse(value);
      this.storage.setItem(this.key, JSON.stringify(value));
    } catch (e) {
      // noop
    }
  }

  remove() {
    this.storage.removeItem(this.key);
  }

  pop(): U | null {
    const value = this.get();
    this.remove();
    return value;
  }
}
