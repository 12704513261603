<template>
  <div class="progress-output">
    <div class="progress-container">
      <div class="progress-content" :style="style"></div>
    </div>
    <div class="progress-text label">{{ userProps.text }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{ userProps: WidgetProps<'progress-output'> }>();

const style = computed(() => {
  const { current, total } = props.userProps;

  return {
    width: `calc(${Math.min((100 * current) / total, 100).toFixed(2)}% - 6px)`,
  };
});
</script>

<style lang="scss" scoped>
@keyframes stripes-move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 46px 0;
  }
}

.progress-output {
  display: flex;
  flex-direction: column;

  .progress-container {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    width: 100%;
    height: 35px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;

    .progress-content {
      border-radius: 20px;
      background: repeating-linear-gradient(
        60deg,
        var(--color-main),
        var(--color-main) 10px,
        var(--color-main-light) 10px,
        var(--color-main-light) 20px
      );
      background-size: 46px 35px;
      height: 29px;
      width: 0;
      margin-left: 3px;
      transition: width 0.2s ease-out;
      animation: stripes-move 2s linear infinite;
    }
  }
}
</style>
