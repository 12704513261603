<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <input
    ref="input"
    :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
    :disabled="userProps.disabled"
    :placeholder="userProps.placeholder"
    @input="handleInput(($event.target as any).value)"
    @keypress="handleKeyPress($event as KeyboardEvent)"
    @change="setErrors"
    @blur="setErrors"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { checkMaskSize, getMaskedValue } from '../../common/mask';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'cnpj-input'>;
  value: WidgetProps<'cnpj-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'cnpj-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const CNPJ_MASK = '00.000.000/0000-00';

const validateCNPJ = (value?: string) => {
  if (!value) return false;

  const isString = typeof value === 'string';
  if (!isString) return false;
  if (value.length > 18) return false;
  const digitsOnly = /^\d{14}$/.test(value);
  const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);
  if (!(digitsOnly || validFormat)) return false;
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  if (numbers.length !== 14) return false;
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  const calc = (x: number) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }
    const result = 11 - (sum % 11);
    return result > 9 ? 0 : result;
  };

  const digits = numbers.slice(12);
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  const digit1 = calc(13);
  return digit1 === digits[1];
};

const input = ref<HTMLInputElement>();

const setErrors = () => {
  const errors = [];

  if (props.value !== '' && (!checkMaskSize(CNPJ_MASK, props.value) || !validateCNPJ(props.value)))
    errors.push(props.userProps.invalidMessage ?? '');

  emit('update:errors', errors);
};

const handleInput = (value: string) => {
  const maskedValue = getMaskedValue(CNPJ_MASK, value);
  input.value!.value = maskedValue;
  emit('update:value', maskedValue);
};

const handleKeyPress = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement;
  if (checkMaskSize(CNPJ_MASK, target.value) && validateCNPJ(target.value)) {
    event.preventDefault();
  }
};

onMounted(() => {
  if (!input.value) return;
  input.value.value = getMaskedValue(CNPJ_MASK, props.value);
});

watch(
  () => props.value,
  () => {
    if (!input.value) return;
    input.value.value = getMaskedValue(CNPJ_MASK, props.value);
  },
);
</script>
