export type CountryCodeMask = {
  code: string;
  placeholder: string;
  mask: string;
};

export const countryCodeMasks: CountryCodeMask[] = [
  { code: '93', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '355', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '213', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '376', placeholder: '000-000', mask: '000-000' },
  { code: '244', placeholder: '(000)000-000', mask: '(000)000-000' },
  {
    code: '1',
    placeholder: '(000)000-0000',
    mask: '(000)000-0000',
  },
  { code: '54', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '374', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '297', placeholder: '000-0000', mask: '000-0000' },
  { code: '61', placeholder: '0-0000-0000', mask: '0-0000-0000' },
  { code: '43', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '994', placeholder: '00-000-00-00', mask: '00-000-00-00' },
  { code: '973', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '880', placeholder: '1000-000000', mask: '1000-000000' },
  { code: '375', placeholder: '(00)000-00-00', mask: '(00)000-00-00' },
  { code: '32', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '501', placeholder: '000-0000', mask: '000-0000' },
  { code: '229', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '975', placeholder: '17-000-000', mask: '17-000-000|0-000-000' },
  { code: '591', placeholder: '0-000-0000', mask: '0-000-0000' },
  { code: '387', placeholder: '00-0000', mask: '00-0000|00-00000' },
  { code: '267', placeholder: '00-000-000', mask: '00-000-000' },
  {
    code: '55',
    placeholder: '(00)0000-0000',
    mask: '(00)0000-0000|(00)00000-0000',
  },
  { code: '673', placeholder: '000-0000', mask: '000-0000' },
  { code: '359', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '226', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '257', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '855', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '237', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '238', placeholder: '(000)00-00', mask: '(000)00-00' },
  { code: '236', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '235', placeholder: '00-00-00-00', mask: '00-00-00-00' },
  { code: '56', placeholder: '0-0000-0000', mask: '0-0000-0000' },
  {
    code: '86',
    placeholder: '(000)0000-000',
    mask: '(000)0000-000|(000)0000-0000|00-00000-00000',
  },
  { code: '57', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '269', placeholder: '00-00000', mask: '00-00000' },
  { code: '242', placeholder: '00-00000', mask: '00-00000' },
  { code: '506', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '385', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '53', placeholder: '0-000-0000', mask: '0-000-0000' },
  { code: '357', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '420', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '243', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '45', placeholder: '00-00-00-00', mask: '00-00-00-00' },
  { code: '253', placeholder: '00-00-00-00', mask: '00-00-00-00' },
  { code: '593', placeholder: '0-000-0000', mask: '0-000-0000|00-000-0000' },
  { code: '20', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '503', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '240', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '291', placeholder: '0-000-000', mask: '0-000-000' },
  { code: '372', placeholder: '000-0000', mask: '000-0000|0000-0000' },
  { code: '268', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '251', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '679', placeholder: '00-00000', mask: '00-00000' },
  { code: '358', placeholder: '(000)000-00-00', mask: '(000)000-00-00' },
  { code: '33', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '241', placeholder: '0-00-00-00', mask: '0-00-00-00' },
  { code: '220', placeholder: '(000)00-00', mask: '(000)00-00' },
  { code: '995', placeholder: '(000)000-000', mask: '(000)000-000' },
  {
    code: '49',
    placeholder: '000-000',
    mask: '000-000|(000)00-00|(000)00-000|(000)00-0000|(000)000-0000|(0000)000-0000',
  },
  { code: '233', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '30', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '502', placeholder: '0-000-0000', mask: '0-000-0000' },
  { code: '224', placeholder: '00-000-000', mask: '00-000-000|00-000-0000' },
  { code: '245', placeholder: '0-000000', mask: '0-000000' },
  { code: '592', placeholder: '000-0000', mask: '000-0000' },
  { code: '509', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '504', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '852', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '36', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '354', placeholder: '000-0000', mask: '000-0000' },
  { code: '91', placeholder: '(0000)000-000', mask: '(0000)000-000' },
  {
    code: '62',
    placeholder: '00-000-00',
    mask: '00-000-00|00-000-000|00-000-0000|(800)000-000|(800)000-00-000',
  },
  { code: '98', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '924', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '353', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '972', placeholder: '0-000-0000', mask: '0-000-0000|50-000-0000' },
  { code: '39', placeholder: '(000)0000-000', mask: '(000)0000-000' },
  { code: '225', placeholder: '00-000-000', mask: '00-000-000' },
  {
    code: '81',
    placeholder: '(000)000-000',
    mask: '(000)000-000|00-0000-0000',
  },
  { code: '962', placeholder: '0-0000-0000', mask: '0-0000-0000' },
  {
    code: '77',
    placeholder: '(600)000-00-00',
    mask: '(600)000-00-00|(700)000-00-00',
  },
  { code: '254', placeholder: '000-000000', mask: '000-000000' },
  {
    code: '850',
    placeholder: '000-000',
    mask: '000-000|0000-0000|00-000-000|000-0000-000|191-000-0000|0000-0000000000000',
  },
  { code: '82', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '965', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '996', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '856', placeholder: '00-000-000', mask: '00-000-000|(2000)000-000' },
  { code: '371', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '961', placeholder: '0-000-000', mask: '0-000-000|00-000-000' },
  { code: '266', placeholder: '0-000-0000', mask: '0-000-0000' },
  { code: '231', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '218', placeholder: '00-000-000', mask: '00-000-000|21-000-0000' },
  { code: '423', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '370', placeholder: '(000)00-000', mask: '(000)00-000' },
  { code: '352', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '261', placeholder: '00-00-00000', mask: '00-00-00000' },
  { code: '265', placeholder: '1-000-000', mask: '1-000-000|0-0000-0000' },
  {
    code: '60',
    placeholder: '0-000-000',
    mask: '0-000-000|00-000-000|(000)000-000|00-000-0000',
  },
  { code: '960', placeholder: '000-0000', mask: '000-0000' },
  { code: '223', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '356', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '596', placeholder: '(000)00-00-00', mask: '(000)00-00-00' },
  { code: '222', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '230', placeholder: '000-0000', mask: '000-0000' },
  { code: '52', placeholder: '00-00-0000', mask: '00-00-0000|(000)000-0000' },
  { code: '691', placeholder: '000-0000', mask: '000-0000' },
  { code: '373', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '377', placeholder: '00-000-000', mask: '00-000-000|(000)000-000' },
  { code: '976', placeholder: '00-00-0000', mask: '00-00-0000' },
  { code: '382', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '212', placeholder: '00-0000-000', mask: '00-0000-000' },
  { code: '258', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '95', placeholder: '000-000', mask: '000-000|0-000-000|00-000-000' },
  { code: '674', placeholder: '000-0000', mask: '000-0000' },
  { code: '977', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '31', placeholder: '00-000-0000', mask: '00-000-0000' },
  {
    code: '24',
    placeholder: '0-000-000',
    mask: '0-000-000|(000)000-000|(000)000-0000',
  },
  { code: '505', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '227', placeholder: '00-00-0000', mask: '00-00-0000' },
  {
    code: '234',
    placeholder: '00-000-00',
    mask: '00-000-00|00-000-000|(000)000-0000',
  },
  { code: '389', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '47', placeholder: '(000)00-000', mask: '(000)00-000' },
  { code: '968', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '92', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '680', placeholder: '000-0000', mask: '000-0000' },
  { code: '970', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '507', placeholder: '000-0000', mask: '000-0000' },
  { code: '675', placeholder: '(000)00-000', mask: '(000)00-000' },
  { code: '595', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '51', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '63', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '48', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '351', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '974', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '40', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '7', placeholder: '(000)000-00-00', mask: '(000)000-00-00' },
  { code: '250', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '685', placeholder: '00-0000', mask: '00-0000' },
  { code: '378', placeholder: '0000-000000', mask: '0000-000000' },
  { code: '239', placeholder: '00-00000', mask: '00-00000' },
  { code: '966', placeholder: '0-000-0000', mask: '0-000-0000|50-0000-0000' },
  { code: '221', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '381', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '248', placeholder: '0-000-000', mask: '0-000-000' },
  { code: '232', placeholder: '00-000000', mask: '00-000000' },
  { code: '65', placeholder: '0000-0000', mask: '0000-0000' },
  { code: '421', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '386', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '677', placeholder: '00000', mask: '00000|000-0000' },
  { code: '252', placeholder: '0-000-000', mask: '0-000-000|00-000-000' },
  { code: '27', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '211', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '34', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '94', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '249', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '597', placeholder: '000-000', mask: '000-000|000-0000' },
  { code: '46', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '41', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '963', placeholder: '00-0000-000', mask: '00-0000-000' },
  { code: '992', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '255', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '66', placeholder: '00-000-000', mask: '00-000-000|00-000-0000' },
  {
    code: '670',
    placeholder: '000-0000',
    mask: '000-0000|770-00000|780-00000',
  },
  { code: '228', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '676', placeholder: '00000', mask: '00000' },
  { code: '216', placeholder: '00-000-000', mask: '00-000-000' },
  { code: '90', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  { code: '993', placeholder: '0-000-0000', mask: '0-000-0000' },
  { code: '256', placeholder: '(000)000-000', mask: '(000)000-000' },
  { code: '380', placeholder: '(00)000-00-00', mask: '(00)000-00-00' },
  { code: '971', placeholder: '0-000-0000', mask: '0-000-0000|50-000-0000' },
  { code: '44', placeholder: '00-0000-0000', mask: '00-0000-0000' },
  { code: '598', placeholder: '0-000-00-00', mask: '0-000-00-00' },
  { code: '998', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '678', placeholder: '00000', mask: '00000|00-00000' },
  { code: '58', placeholder: '(000)000-0000', mask: '(000)000-0000' },
  {
    code: '84',
    placeholder: '00-0000-000',
    mask: '00-0000-000|(000)0000-000',
  },
  {
    code: '967',
    placeholder: '0-000-000',
    mask: '0-000-000|00-000-000|000-000-000',
  },
  { code: '260', placeholder: '00-000-0000', mask: '00-000-0000' },
  { code: '263', placeholder: '', mask: '' },
];
