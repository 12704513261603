<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <drop-area
    :value="value"
    :errors="errors"
    :accepted-formats="userProps.acceptedFormats || ['*']"
    :accepted-mime-types="userProps.acceptedMimeTypes"
    :multiple="userProps.multiple"
    :max-file-size="userProps.maxFileSize"
    :disabled="userProps.disabled"
    :locale="locale"
    @update:value="handleUpdateValue"
    @update:errors="$emit('update:errors', $event)"
  />
</template>

<script lang="ts" setup>
import { SupportedLocales } from '../../common/i18n';
import WidgetsLabel from '../../common/components/Label.vue';
import DropArea from '../../common/components/upload/DropArea.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

defineProps<{
  userProps: WidgetProps<'file-input'>;
  errors: string[];
  value: WidgetProps<'file-input'>['value'];
  locale: SupportedLocales;
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'file-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const handleUpdateValue = (value: WidgetProps<'file-input'>['value']) => {
  emit('update:value', value);
};
</script>
