<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <div class="tag-input">
    <div class="tags">
      <div v-for="(tag, index) in tags" :key="tag" class="tag">
        {{ tag }}
        <ant-button class="remove-tag" type="text" @click="removeTag(index)">
          <svg class="remove-icon" viewBox="0 0 24 24">
            <path :d="removeIcon" />
          </svg>
        </ant-button>
      </div>
    </div>
    <input
      ref="input"
      :class="['input', { disabled: userProps.disabled }]"
      :disabled="userProps.disabled"
      type="text"
      :placeholder="userProps.placeholder"
      @change="addTag(($event.target as any).value)"
      @keyup.enter="addTag(($event.target as any).value)"
    />
  </div>
</template>

<script lang="ts" setup>
import { Button as AntButton } from 'ant-design-vue';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { close } from '../../common/icons';
import type { WidgetProps } from '../../generated/widgetTypes';

const removeIcon = close;

const props = defineProps<{
  userProps: WidgetProps<'tag-input'>;
  value: WidgetProps<'tag-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'tag-input'>['value']): void;
}>();

const tags = ref(props.value ?? []);

const addTag = (value: string | number | null) => {
  if (props.userProps.disabled || !value) return;

  if (tags.value.some((tag) => tag === value)) return;

  tags.value = [...tags.value, value];

  emit('update:value', tags.value);

  input.value!.value = '';
};

const removeTag = (tagIndex: number) => {
  if (props.userProps.disabled) return;
  tags.value = tags.value.filter((_t, index) => index !== tagIndex);

  emit('update:value', tags.value);
};

const input = ref<HTMLInputElement>();

watch(
  () => props.value,
  () => {
    tags.value = props.value ?? [];
  },
);
</script>

<style lang="scss" scoped>
.tag-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
.tag {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
}
.tag:last-child {
  margin-right: 0;
}
.remove-tag {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 4px;
  padding: 1px 6px;
  height: 20px;
}
.remove-icon {
  width: 1rem;
  height: 1rem;
}
.label {
  margin-bottom: 0.5rem;
}
.input {
  align-self: flex-start;
}
</style>
