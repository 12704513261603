export const locales = [
  { key: 'en', value: 'English' },
  { key: 'pt', value: 'Portuguese' },
  { key: 'es', value: 'Spanish' },
  { key: 'de', value: 'German' },
  { key: 'fr', value: 'French' },
  { key: 'hi', value: 'Hindi' },
] as const;

export type SupportedLocales = (typeof locales)[number]['key'];

type StringsMap = Record<string, (params: any) => Record<SupportedLocales[number], string>>;

const translations = {
  i18n_camera_input_take_photo: () => ({
    en: 'Take photo',
    pt: 'Tirar foto',
    es: 'Tomar foto',
    de: 'Foto aufnehmen',
    fr: 'Prendre une photo',
    hi: 'फोटो लें',
  }),
  i18n_camera_input_try_again: () => ({
    en: 'Try again',
    pt: 'Tentar novamente',
    es: 'Intentar de nuevo',
    de: 'Erneut versuchen',
    fr: 'Réessayer',
    hi: 'पुन: प्रयास करें',
  }),
  i18n_upload_area_click_or_drop_files: () => ({
    en: 'Click or drag file here to upload',
    pt: 'Clique ou arraste o arquivo aqui para fazer upload',
    es: 'Haz clic o arrastra el archivo aquí para subirlo',
    de: 'Klicken oder ziehen Sie die Datei hierher, um sie hochzuladen',
    fr: 'Cliquez ou faites glisser le fichier ici pour le télécharger',
    hi: 'अपलोड करने के लिए यहाँ क्लिक करें या फ़ाइल खींचें',
  }),
  i18n_upload_area_drop_here: () => ({
    en: 'Drop files',
    pt: 'Solte os arquivos',
    es: 'Suelta los archivos',
    de: 'Dateien ablegen',
    fr: 'Déposer les fichiers',
    hi: 'फ़ाइलें ड्रॉप करें',
  }),
  i18n_upload_area_rejected_file_extension: (args: { formats: string }) => ({
    en: `Invalid file extension. Expected formats: ${args.formats}`,
    pt: `Extensão de arquivo inválida. Formatos aceitos: ${args.formats}`,
    es: `Extensión de archivo inválida. Formatos aceptados: ${args.formats}`,
    de: `Ungültige Dateierweiterung. Akzeptierte Formate: ${args.formats}`,
    fr: `Extension de fichier invalide. Formats acceptés: ${args.formats}`,
    hi: `अमान्य फ़ाइल एक्सटेंशन। अपेक्षित फ़ॉर्मेट्स: ${args.formats}`,
  }),
  i18n_upload_max_size_excided: (args: { fileName: string; maxSize: number }) => ({
    en: `File ${args.fileName} exceeds size limit of ${args.maxSize}MB`,
    pt: `Arquivo ${args.fileName} excede o limite de tamanho de ${args.maxSize}MB`,
    es: `El archivo ${args.fileName} excede el límite de tamaño de ${args.maxSize}MB`,
    de: `Die Datei ${args.fileName} überschreitet das Größenlimit von ${args.maxSize}MB`,
    fr: `Le fichier ${args.fileName} dépasse la limite de taille de ${args.maxSize}MB`,
    hi: `फ़ाइल ${args.fileName} ${args.maxSize}MB की सीमा से अधिक है`,
  }),
  i18n_upload_failed: (args: { fileName: string }) => ({
    en: `File upload failed for ${args.fileName}`,
    pt: `Falha ao enviar arquivo ${args.fileName}`,
    es: `Error al subir archivo ${args.fileName}`,
    de: `Datei-Upload fehlgeschlagen für ${args.fileName}`,
    fr: `Échec du téléchargement du fichier ${args.fileName}`,
    hi: `${args.fileName} के लिए फ़ाइल अपलोड विफल रहा`,
  }),
  i18n_login_with_this_project: () => ({
    en: 'Use this project',
    pt: 'Usar este projeto',
    es: 'Usar este proyecto',
    de: 'Dieses Projekt verwenden',
    fr: 'Utiliser ce projet',
    hi: 'इस परियोजना का उपयोग करें',
  }),
  i18n_watermark_text: () => ({
    en: 'Coded in Python with',
    pt: 'Escrito em Python com',
    es: 'Escrito en Python con',
    de: 'In Python mit',
    fr: 'Codé en Python avec',
    hi: 'पायथन में लिखा गया',
  }),
  i18n_error_invalid_email: () => ({
    en: 'This email is invalid.',
    pt: 'Este email é inválido.',
    es: 'Este email es inválido.',
    de: 'Diese E-Mail ist ungültig.',
    fr: 'Cet email est invalide.',
    hi: 'यह ईमेल अमान्य है।',
  }),
  i18n_error_required_field: () => ({
    en: 'This field is required.',
    pt: 'Este campo é obrigatório.',
    es: 'Este campo es obligatorio.',
    de: 'Dieses Feld ist erforderlich.',
    fr: 'Ce champ est obligatoire.',
    hi: 'यह फ़ील्ड आवश्यक है।',
  }),
  i18n_error_invalid_cnpj: () => ({
    en: 'This CNPJ is invalid.',
    pt: 'Este CNPJ é inválido.',
    es: 'Este CNPJ es inválido.',
    de: 'Diese CNPJ ist ungültig.',
    fr: 'Ce CNPJ est invalide.',
    hi: 'यह CNPJ अमान्य है।',
  }),
  i18n_error_invalid_cpf: () => ({
    en: 'This CPF is invalid.',
    pt: 'Este CPF é inválido.',
    es: 'Este CPF es inválido.',
    de: 'Diese CPF ist ungültig.',
    fr: 'Ce CPF est invalide.',
    hi: 'यह CPF अमान्य है।',
  }),
  i18n_error_invalid_phone_number: () => ({
    en: 'This phone number is invalid.',
    pt: 'Este número de telefone é inválido.',
    es: 'Este número de teléfono es inválido.',
    de: 'Diese Telefonnummer ist ungültig.',
    fr: 'Ce numéro de téléphone est invalide.',
    hi: 'यह फ़ोन नंबर अमान्य है।',
  }),
  i18n_error_invalid_country_code: () => ({
    en: 'This country code is invalid.',
    pt: 'Este código de país é inválido.',
    es: 'Este código de país es inválido.',
    de: 'Dieser Ländercode ist ungültig.',
    fr: 'Ce code de pays est invalide.',
    hi: 'यह देश कोड अमान्य है।',
  }),
  i18n_error_min_list: (args: { min: number }) => ({
    en: `The minimum number of items is ${args.min}.`,
    pt: `O número mínimo de itens é ${args.min}.`,
    es: `El número mínimo de ítems es ${args.min}.`,
    de: `Die Mindestanzahl an Elementen beträgt ${args.min}.`,
    fr: `Le nombre minimum d'éléments est ${args.min}.`,
    hi: `आइटमों की न्यूनतम संख्या ${args.min} है।`,
  }),
  i18n_error_max_list: (args: { max: number }) => ({
    en: `The maximum number of items is ${args.max}.`,
    pt: `O número máximo de itens é ${args.max}.`,
    es: `El número máximo de ítems es ${args.max}.`,
    de: `Die maximale Anzahl an Elementen beträgt ${args.max}.`,
    fr: `Le nombre maximum d'éléments est ${args.max}.`,
    hi: `आइटमों की अधिकतम संख्या ${args.max} है।`,
  }),
  i18n_error_invalid_list_item: () => ({
    en: 'Some fields are invalid.',
    pt: 'Alguns campos são inválidos.',
    es: 'Algunos campos son inválidos.',
    de: 'Einige Felder sind ungültig.',
    fr: 'Certains champs sont invalides.',
    hi: 'कुछ फ़ील्ड अमान्य हैं।',
  }),
  i18n_error_min_number: (args: { min: number }) => ({
    en: `The minimum value is ${args.min}.`,
    pt: `O valor mínimo é ${args.min}.`,
    es: `El valor mínimo es ${args.min}.`,
    de: `Der Mindestwert beträgt ${args.min}.`,
    fr: `La valeur minimale est ${args.min}.`,
    hi: `न्यूनतम मूल्य ${args.min} है।`,
  }),
  i18n_error_max_number: (args: { max: number }) => ({
    en: `The maximum value is ${args.max}.`,
    pt: `O valor máximo é ${args.max}.`,
    es: `El valor máximo es ${args.max}.`,
    de: `Der maximale Wert beträgt ${args.max}.`,
    fr: `La valeur maximale est ${args.max}.`,
    hi: `अधिकतम मू$ल्य ${args.max} है।`,
  }),
  i18n_error_max_amount: (args: { max: number; currency: string }) => ({
    en: `The maximum amount is ${args.max} ${args.currency}.`,
    pt: `O valor máximo é ${args.max} ${args.currency}.`,
    es: `El valor máximo es ${args.max} ${args.currency}.`,
    de: `Der maximale Betrag beträgt ${args.max} ${args.currency}.`,
    fr: `Le montant maximum est ${args.max} ${args.currency}.`,
    hi: `अधिकतम राशि ${args.max} ${args.currency} है।`,
  }),
  i18n_error_min_amount: (args: { min: number; currency: string }) => ({
    en: `The minimum amount is ${args.min} ${args.currency}.`,
    pt: `O valor mínimo é ${args.min} ${args.currency}.`,
    es: `El valor mínimo es ${args.min} ${args.currency}.`,
    de: `Der minimale Betrag beträgt ${args.min} ${args.currency}.`,
    fr: `Le montant minimum est ${args.min} ${args.currency}.`,
    hi: `न्यूनतम राशि ${args.min} ${args.currency} है।`,
  }),
  i18n_generic_validation_error: () => ({
    en: 'There are errors in the form.',
    pt: 'Existem erros no formulário.',
    es: 'Hay errores en el formulario.',
    de: 'Es gibt Fehler im Formular.',
    fr: 'Il y a des erreurs dans le formulaire.',
    hi: 'फॉर्म में त्रुटियां हैं।',
  }),
  i18n_back_action: () => ({
    en: 'Back',
    pt: 'Voltar',
    es: 'Volver',
    de: 'Zurück',
    fr: 'Retour',
    hi: 'वापस',
  }),
  i18n_start_action: () => ({
    en: 'Start',
    pt: 'Iniciar',
    es: 'Comenzar',
    de: 'Starten',
    fr: 'Démarrer',
    hi: 'शुरू',
  }),
  i18n_restart_action: () => ({
    en: 'Restart',
    pt: 'Reiniciar',
    es: 'Reiniciar',
    de: 'Neustarten',
    fr: 'Redémarrer',
    hi: 'पुनः आरंभ करें',
  }),
  i18n_next_action: () => ({
    en: 'Next',
    pt: 'Próximo',
    es: 'Siguiente',
    de: 'Nächster',
    fr: 'Suivant',
    hi: 'अगला',
  }),
  i18n_end_message: () => ({
    en: 'Thank you',
    pt: 'Obrigado',
    es: 'Gracias',
    de: 'Danke',
    fr: 'Merci',
    hi: 'धन्यवाद',
  }),
  i18n_error_message: () => ({
    en: 'Oops... something went wrong.',
    pt: 'Oops... algo deu errado.',
    es: 'Oops... algo salió mal.',
    de: 'Oops... etwas ist schief gelaufen.',
    fr: "Oops... quelque chose s'est mal passé.",
    hi: 'उह... कुछ गलत हो गया।',
  }),
  i18n_lock_failed_running: () => ({
    en: 'This form is already being filled',
    pt: 'Este formulário já está sendo preenchido',
    es: 'Este formulario ya está siendo completado',
    de: 'Dieses Formular wird bereits ausgefüllt',
    fr: 'Ce formulaire est déjà en cours de remplissage',
    hi: 'यह फॉर्म पहले से भर रहा है',
  }),
  i18n_lock_failed_not_running: () => ({
    en: 'This form was already filled',
    pt: 'Este formulário já foi preenchido',
    es: 'Este formulario ya fue completado',
    de: 'Dieses Formular wurde bereits ausgefüllt',
    fr: 'Ce formulaire a déjà été rempli',
    hi: 'यह फॉर्म पहले से भरा गया था',
  }),
  i18n_auth_validate_your_email: () => ({
    en: 'Validate your email',
    pt: 'Valide seu email',
    es: 'Valida tu email',
    de: 'Überprüfen Sie Ihre E-Mail',
    fr: 'Validez votre email',
    hi: 'अपना ईमेल सत्यापित करें',
  }),
  i18n_auth_info_description: () => ({
    en: 'Please enter your work email and continue to receive a verification code.',
    pt: 'Por favor, insira seu email de trabalho e continue para receber um código de verificação.',
    es: 'Por favor, introduce tu email de trabajo y continúa para recibir un código de verificación.',
    de: 'Bitte geben Sie Ihre Arbeits-E-Mail-Adresse ein und fahren Sie fort, um einen Bestätigungscode zu erhalten.',
    fr: 'Veuillez entrer votre email de travail et continuer pour recevoir un code de vérification.',
    hi: 'कृपया अपना काम का ईमेल दर्ज करें और एक सत्यापन कोड प्राप्त करने के लिए जारी रखें।',
  }),
  i18n_auth_validate_your_email_login: (args: { brandName?: string }) => ({
    en: `Login to ${args.brandName ||'Abstra Project'}`,
    pt: `Entrar na ${args.brandName ||'Abstra Project'}`,
    es: `Iniciar sesión en ${args.brandName ||'Abstra Project'}`,
    de: `Anmelden bei ${args.brandName ||'Abstra Project'}`,
    fr: `Se connecter à ${args.brandName ||'Abstra Project'}`,
    hi: `${args.brandName ||'Abstra Project'} में लॉग इन करें`,
  }),
  i18n_auth_enter_your_work_email: () => ({
    en: "Work email address",
    pt: 'Endereço de email de trabalho',
    es: 'Dirección de correo electrónico de trabajo',
    de: 'Arbeits-E-Mail-Adresse',
    fr: 'Adresse e-mail professionnelle',
    hi: 'काम का ईमेल पता',
  }),
  i18n_auth_enter_your_email: () => ({
    en: "Email address",
    pt: 'Endereço de email',
    es: 'Dirección de correo electrónico',
    de: 'E-Mail-Adresse',
    fr: 'Adresse e-mail',
    hi: 'ईमेल पता',
  }),
  i18n_auth_enter_your_token: () => ({
    en: "Type your verification code",
    pt: 'Digite seu código de verificação',
    es: 'Escribe tu código de verificación',
    de: 'Geben Sie Ihren Bestätigungscode ein',
    fr: 'Tapez votre code de vérification',
    hi: 'अपना सत्यापन कोड टाइप करें',
  }),
  i18n_connectors_ask_for_access: (connectorName: string) => ({
    en: `I would like to connect my ${connectorName} account`,
    pt: `Gostaria de conectar minha conta ${connectorName}`,
    es: `Me gustaría conectar mi cuenta de ${connectorName}`,
    de: `Ich möchte mein ${connectorName}-Konto verbinden`,
    fr: `Je voudrais connecter mon compte ${connectorName}`,
    hi: `मैं अपना ${connectorName} खाता कनेक्ट करना चाहूंगा`,
  }),
  i18n_create_or_choose_project: () => ({
    en: 'Select or create a new project',
    pt: 'Selecione ou crie um novo projeto',
    es: 'Selecciona o crea un nuevo proyecto',
    de: 'Wählen oder erstellen Sie ein neues Projekt',
    fr: 'Sélectionnez ou créez un nouveau projet',
    hi: 'एक नया परियोजना चुनें या बनाएं',
  }),
  i18n_get_api_key_organization: () => ({
    en: 'Organization',
    pt: 'Organização',
    es: 'Organización',
    de: 'Organisation',
    fr: 'Organisation',
    hi: 'संगठन',
  }),
  i18n_get_api_key_choose_organization: () => ({
    en: 'Choose an organization',
    pt: 'Escolha uma organização',
    es: 'Elige una organización',
    de: 'Wählen Sie eine Organisation',
    fr: 'Choisissez une organisation',
    hi: 'एक संगठन चुनें',
  }),
  i18n_get_api_key_new_organization: () => ({
    en: 'New organization',
    pt: 'Nova organização',
    es: 'Nueva organización',
    de: 'Neue Organisation',
    fr: 'Nouvelle organisation',
    hi: 'नया संगठन',
  }),
  i18n_get_api_key_existing_organizations: () => ({
    en: 'Existing organizations',
    pt: 'Organizações existentes',
    es: 'Organizaciones existentes',
    de: 'Bestehende Organisationen',
    fr: 'Organisations existantes',
    hi: 'मौजूदा संगठन',
  }),
  i18n_get_api_key_organization_name: () => ({
    en: 'Organization name',
    pt: 'Nome da organização',
    es: 'Nombre de la organización',
    de: 'Organisationsname',
    fr: "Nom de l'organisation",
    hi: 'संगठन का नाम',
  }),
  i18n_get_api_key_choose_organization_name: () => ({
    en: 'Choose a name for your new organization',
    pt: 'Escolha um nome para a sua nova organização',
    es: 'Elige un nombre para tu nueva organización',
    de: 'Wählen Sie einen Namen für Ihre neue Organisation',
    fr: 'Choisissez un nom pour votre nouvelle organisation',
    hi: 'अपने नए संगठन का नाम चुनें',
  }),
  i18n_get_api_key_project: () => ({
    en: 'Project',
    pt: 'Projeto',
    es: 'Proyecto',
    de: 'Projekt',
    fr: 'Projet',
    hi: 'परियोजना',
  }),
  i18n_get_api_key_choose_project: () => ({
    en: 'Choose a project',
    pt: 'Escolha um projeto',
    es: 'Elige un proyecto',
    de: 'Wählen Sie ein Projekt',
    fr: 'Choisissez un projet',
    hi: 'एक परियोजना चुनें',
  }),
  i18n_get_api_key_project_name: () => ({
    en: 'Project name',
    pt: 'Nome do projeto',
    es: 'Nombre del proyecto',
    de: 'Projektname',
    fr: 'Nom du projet',
    hi: 'परियोजना का नाम',
  }),
  i18n_get_api_key_choose_project_name: () => ({
    en: 'Choose a name for your new project',
    pt: 'Escolha um nome para o seu novo projeto',
    es: 'Elige un nombre para tu nuevo proyecto',
    de: 'Wählen Sie einen Namen für Ihr neues Projekt',
    fr: 'Choisissez un nom pour votre nouveau projet',
    hi: 'अपने नए परियोजना का नाम चुनें',
  }),
  i18n_get_api_key_create_new_organization: () => ({
    en: 'Create new organization',
    pt: 'Criar nova organização',
    es: 'Crear nueva organización',
    de: 'Neue Organisation erstellen',
    fr: 'Créer une nouvelle organisation',
    hi: 'नया संगठन बनाएं',
  }),
  i18n_get_api_key_new_project: () => ({
    en: 'New project',
    pt: 'Novo projeto',
    es: 'Nuevo proyecto',
    de: 'Neues Projekt',
    fr: 'Nouveau projet',
    hi: 'नई परियोजना',
  }),
  i18n_get_api_key_existing_projects: () => ({
    en: 'Existing projects',
    pt: 'Projetos existentes',
    es: 'Proyectos existentes',
    de: 'Bestehende Projekte',
    fr: 'Projets existants',
    hi: 'मौजूदा परियोजनाएं',
  }),
  i18n_get_api_key_create_new_project: () => ({
    en: 'Create new project',
    pt: 'Criar novo projeto',
    es: 'Crear nuevo proyecto',
    de: 'Neues Projekt erstellen',
    fr: 'Créer un nouveau projet',
    hi: 'नई परियोजना बनाएं',
  }),
  i18n_get_api_key_api_key_info: () => ({
    en: 'Use this API key to access your cloud resources.',
    pt: 'Use esta chave de API para acessar seus recursos na nuvem.',
    es: 'Utiliza esta clave de API para acceder a tus recursos en la nube.',
    de: 'Verwenden Sie diesen API-Schlüssel, um auf Ihre Cloud-Ressourcen zuzugreifen.',
    fr: 'Utilisez cette clé API pour accéder à vos ressources cloud.',
    hi: 'अपने क्लाउड संसाधनों तक पहुंच के लिए इस API कुंजी का उपयोग करें।',
  }),
  i18n_get_api_key_api_key_warning: () => ({
    en: 'This is a secret key. Do not share it with anyone and make sure to store it in a safe place.',
    pt: 'Esta é uma chave secreta. Não a compartilhe com ninguém e certifique-se de armazená-la em um local seguro.',
    es: 'Esta es una clave secreta. No la compartas con nadie y asegúrate de guardarla en un lugar seguro.',
    de: 'Dies ist ein geheimer Schlüssel. Teilen Sie es nicht mit anderen und stellen Sie sicher, dass Sie es an einem sicheren Ort aufbewahren.',
    fr: "C'est une clé secrète. Ne le partagez avec personne et assurez-vous de le stocker dans un endroit sûr.",
    hi: 'यह एक गुप्त कुंजी है। इसे किसी के साथ साझा न करें और सुनिश्चित करें कि आप इसे सुरक्षित स्थान पर स्टोर करें।',
  }),
  i18n_auth_info_invalid_email: () => ({
    en: 'Invalid email, please try again.',
    pt: 'Email inválido, tente novamente.',
    es: 'Email inválido, inténtalo de nuevo.',
    de: 'E-Mail ungültig, bitte versuchen Sie es erneut.',
    fr: 'Email invalide, veuillez réessayer.',
    hi: 'ईमेल अमान्य है, कृपया पुन: प्रयास करें।',
  }),
  i18n_auth_info_send_code: () => ({
    en: 'Send verification code',
    pt: 'Enviar código de verificação',
    es: 'Enviar código de verificación',
    de: 'Bestätigungscode senden',
    fr: 'Envoyer le code de vérification',
    hi: 'सत्यापन कोड भेजें',
  }),
  i18n_auth_token_label: (args: { email: string }) => ({
    en: `Check ${args.email}'s inbox and enter your verification code below`,
    pt: `Verifique a caixa de entrada de ${args.email} e insira o código de verificação abaixo`,
    es: `Revisa la bandeja de entrada de ${args.email} y escribe el código de verificación abajo`,
    de: `Überprüfen Sie den Posteingang von ${args.email} und geben Sie den Bestätigungscode unten ein`,
    fr: `Vérifiez la boîte de réception de ${args.email} et entrez le code de vérification ci-dessous`,
    hi: `जाँच करें ${args.email} की इनबॉक्स और नीचे अपना सत्यापन कोड दर्ज करें`,
  }),
  i18n_auth_token_development_warning: () => ({
    en: 'You’re in development mode, any token will work',
    pt: 'Você está em modo de desenvolvimento, qualquer token funcionará',
    es: 'Estás en modo de desarrollo, cualquier token funcionará',
    de: 'Sie sind im Entwicklungsmodus, jeder Token funktioniert',
    fr: 'Vous êtes en mode développement, n’importe quel token fonctionnera',
    hi: 'आप विकास मोड में हैं, कोई भी टोकन काम करेगा',
  }),
  i18n_auth_token_expired: () => ({
    en: 'Token has expired, please retry sending it.',
    pt: 'Token expirou, tente reenviar.',
    es: 'Token ha expirado, inténtalo reenviándolo.',
    de: 'Token ist abgelaufen, bitte versuchen Sie es erneut zu senden.',
    fr: 'Token est expiré, essayez de le renvoyer.',
    hi: 'टोकन समय सीमा समाप्त, इसे फिर से भेजने का प्रयास करें।',
  }),
  i18n_auth_token_invalid: () => ({
    en: 'Invalid token, please try again or go back and change you email address.',
    pt: 'Token inválido, tente novamente ou volte e altere o seu endereço de email.',
    es: 'Token inválido, por favor intenta de nuevo o vuelve y cambia tu dirección de correo electrónico.',
    de: 'Token ungültig, bitte versuchen Sie es erneut oder gehen Sie zurück und ändern Sie Ihre E-Mail Adresse.',
    fr: 'Token invalide, veuillez réessayer ou revenir en arrière et changez votre adresse e-mail.',
    hi: 'टोकन अमान्य है, कृपया पुन: प्रयास करें या वापस जाएं और आपका ईमेल पता बदलें।',
  }),
  i18n_auth_token_verify_email: () => ({
    en: 'Verify email',
    pt: 'Verificar email',
    es: 'Verificar email',
    de: 'E-Mail verifizieren',
    fr: 'Vérifier email',
    hi: 'ईमेल सत्यापित करें',
  }),
  i18n_auth_edit_email: () => ({
    en: 'Use another email',
    pt: 'Usar outro email',
    es: 'Usar otro email',
    de: 'Eine andere E-Mail-Adresse verwenden',
    fr: 'Utiliser un autre email',
    hi: 'दूसरा ईमेल प्रयोग करें',
  }),
  i18n_auth_token_resend_email: () => ({
    en: 'Send new code',
    pt: 'Enviar novo código',
    es: 'Enviar nuevo código',
    de: 'Neuen Code senden',
    fr: 'Envoyer un nouveau code',
    hi: 'नया कोड भेजें',
  }),
  i18n_auth_token_footer_alternative_email: () => ({
    en: "If you haven't received the verification code, please check your spam folder",
    pt: 'Se você não recebeu o código de verificação, verifique sua caixa de spam',
    es: 'Si no has recibido el código de verificación, por favor revisa tu carpeta de spam',
    de: 'Wenn Sie den Bestätigungscode nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner',
    fr: "Si vous n'avez pas reçu le code de vérification, veuillez vérifier votre dossier de spam",
    hi: 'यदि आपने सत्यापन कोड प्राप्त नहीं किया है, तो कृपया अपने स्पैम फ़ोल्डर की जाँच करें',
  }),
  i18n_local_auth_info_description: () => ({
    en: 'In development mode any email and code will be accepted.',
    pt: 'No modo de desenvolvimento qualquer email e código serão aceitos.',
    es: 'En modo de desarrollo cualquier email y código serán aceptados.',
    de: 'Im Entwicklungsmodus werden jede E-Mail und jeder Code akzeptiert.',
    fr: 'En mode développement, tout email et code seront acceptés.',
    hi: 'विकास मोड में किसी भी ईमेल और कोड को स्वीकार किया जाएगा।',
  }),
  i18n_local_auth_info_authenticate: () => ({
    en: 'Validate email',
    pt: 'Validar email',
    es: 'Validar email',
    de: 'E-Mail bestätigen',
    fr: 'Valider email',
    hi: 'ईमेल सत्यापित करें',
  }),
  i18n_error_invalid_date: () => ({
    en: 'Invalid date',
    pt: 'Data inválida',
    es: 'Fecha no válida',
    de: 'Ungültiges Datum',
    fr: 'Date invalide',
    hi: 'अवैध तिथि',
  }),
  i18n_camera_permission_denied: () => ({
    en: 'Camera access denied',
    pt: 'Acesso à câmera negado',
    es: 'Acceso a la cámara denegado',
    de: 'Kamerazugriff verweigert',
    fr: 'Accès à la caméra refusé',
    hi: 'कैमरा एक्सेस नाइटेड',
  }),
  i18n_no_camera_found: () => ({
    en: 'No camera found',
    pt: 'Nenhuma câmera encontrada',
    es: 'No se encontró ninguna cámara',
    de: 'Keine Kamera gefunden',
    fr: 'Aucune caméra trouvée',
    hi: 'कोई कैमरा नहीं मिला',
  }),
  i18n_camera_already_in_use: () => ({
    en: 'Camera is already in use',
    pt: 'Câmera já está em uso',
    es: 'La cámara ya está en uso',
    de: 'Kamera wird bereits verwendet',
    fr: 'La caméra est déjà utilisée',
    hi: 'कैमरा पहले से ही उपयोग में है',
  }),
  i18n_permission_error: () => ({
    en: 'An error occurred while accessing the camera',
    pt: 'Ocorreu um erro ao acessar a câmera',
    es: 'Se produjo un error al acceder a la cámara',
    de: 'Beim Zugriff auf die Kamera ist ein Fehler aufgetreten',
    fr: "Une erreur s'est produite lors de l'accès à la caméra",
    hi: 'कैमरा तक पहुंचते समय एक त्रुटि आई',
  }),
  i18n_access_denied: () => ({
    en: 'Access denied',
    pt: 'Acesso negado',
    es: 'Acceso denegado',
    de: 'Zugriff verweigert',
    fr: 'Accès refusé',
    hi: 'पहुंच निषेधित',
  }),
  i18n_access_denied_message: () => ({
    en: 'You do not have the necessary permissions to access this page. Please contact the administrator to request access.',
    pt: 'Você não tem as permissões necessárias para acessar esta página. Entre em contato com o administrador para solicitar acesso.',
    es: 'No tienes los permisos necesarios para acceder a esta página. Ponte en contacto con el administrador para solicitar acceso.',
    de: 'Sie haben nicht die erforderlichen Berechtigungen, um auf diese Seite zuzugreifen. Bitte kontaktieren Sie den Administrator, um Zugriff anzufordern.',
    fr: "Vous n'avez pas les autorisations nécessaires pour accéder à cette page. Veuillez contacter l'administrateur pour demander l'accès.",
    hi: 'आपके पास इस पेज तक पहुंचने के लिए आवश्यक अनुमतियाँ नहीं हैं। कृपया पहुंच के लिए अनुरोध करने के लिए प्रशासक से संपर्क करें।',
  }),
  i18n_internal_error: () => ({
    en: 'Internal error',
    pt: 'Erro interno',
    es: 'Error interno',
    de: 'Interner Fehler',
    fr: 'Erreur interne',
    hi: 'आंतरिक त्रुटि',
  }),
  i18n_internal_error_message: () => ({
    en: 'An unknown error ocurred. Please try again or contact support.',
    pt: 'Ocorreu um erro desconhecido. Tente novamente ou entre em contato com o suporte.',
    es: 'Se ha producido un error desconocido. Inténtalo de nuevo o ponte en contacto con el soporte.',
    de: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
    fr: 'Une erreur inconnue s’est produite. Veuillez réessayer ou contacter le support.',
    hi: 'एक अज्ञात त्रुटि हुई। कृपया पुन: प्रयास करें या समर्थन से संपर्क करें।',
  }),
  i18n_page_not_found: () => ({
    en: 'Page not found',
    pt: 'Página não encontrada',
    es: 'Página no encontrada',
    de: 'Seite nicht gefunden',
    fr: 'Page non trouvée',
    hi: 'पृष्ठ नहीं मिला',
  }),
  i18n_page_not_found_message: () => ({
    en: 'The page you are looking for does not exist. Please check the URL and try again.',
    pt: 'A página que você está procurando não existe. Verifique a URL e tente novamente.',
    es: 'La página que buscas no existe. Por favor, verifica la URL e inténtalo de nuevo.',
    de: 'Die von Ihnen gesuchte Seite existiert nicht. Bitte überprüfen Sie die URL und versuchen Sie es erneut.',
    fr: 'La page que vous recherchez n’existe pas. Veuillez vérifier l’URL et réessayer.',
    hi: 'आप जिस पृष्ठ की खोज कर रहे हैं, वह मौजूद नहीं है। कृपया URL की जाँच करें और पुन: प्रयास करें।',
  }),
  i18n_error_invalid_file_format: (args: { acceptedFormats: string }) => ({
    en: `Invalid file extension. Expected formats: ${args.acceptedFormats}`,
    pt: `Extensão de arquivo inválida. Formatos aceitos: ${args.acceptedFormats}`,
    es: `Extensión de archivo inválida. Formatos aceptados: ${args.acceptedFormats}`,
    de: `Ungültige Dateierweiterung. Akzeptierte Formate: ${args.acceptedFormats}`,
    fr: `Extension de fichier invalide. Formats acceptés: ${args.acceptedFormats}`,
    hi: `अमान्य फ़ाइल एक्सटेंशन। अपेक्षित फ़ॉर्मेट्स: ${args.acceptedFormats}`,
  }),
} satisfies StringsMap;
export type TranslationKey = keyof typeof translations;

const DEFAULT_LOCALE = 'en';

class I18nProvider {
  private getBrowserLocale(): string {
    const locale = navigator.language;
    const parts = locale.split(/[-_]/);
    return parts[0];
  }

  private getSupportedLocale(locale: string): SupportedLocales {
    if (!locales.includes(locale as any)) {
      return DEFAULT_LOCALE;
    } else {
      return locale as SupportedLocales;
    }
  }

  translate<K extends TranslationKey>(
    key: K,
    locale: SupportedLocales | null = null,
    ...params: Parameters<(typeof translations)[K]>
  ): string {
    if (!(key in translations)) {
      throw new Error(`Missing translation for key ${key} in locale ${locale}`);
    }
    if (locale == null) {
      const browserLocale = this.getBrowserLocale();
      const supportedLocale = i18nProvider.getSupportedLocale(browserLocale);
      // @ts-ignore
      return translations[key](...params)[supportedLocale];
    }
    // @ts-ignore
    return translations[key](...params)[locale];
  }

  translateIfFound(key: string, locale: SupportedLocales | null, ...params: any): string {
    if (key in translations) {
      return this.translate(key as TranslationKey, locale, ...params);
    }
    return key;
  }
}

export const i18nProvider = new I18nProvider();
