<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <div class="appointment-input">
    <ant-calendar
      :value="selectedDate"
      :disabled-date="disabledDate"
      :fullscreen="false"
      :valid-range="validRange"
      :default-value="initialDate"
      @select="handleSelect"
      @panel-change="handlePanelChange"
    />
    <ant-flex v-if="selectedSlots.length > 0" vertical gap="small">
      <ant-title :level="4">Available slots</ant-title>
      <div
        style="
          position: relative;
          min-width: 200px;
          min-height: 200px;
          height: 100%;

          overflow-y: auto;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            gap: 4px;
          "
        >
          <ant-button
            v-for="{ slot, idx } in selectedSlots"
            :key="idx"
            :type="idx === userProps.value ? 'primary' : 'default'"
            @click="slotClicked(idx)"
          >
            {{ dayjs(slot.begin).format('hh:mm A') }} - {{ dayjs(slot.end).format('hh:mm A') }}
          </ant-button>
        </div>
      </div>
    </ant-flex>
    <ant-empty v-else description="No slots available" />
  </div>
</template>

<script lang="ts" setup>
import {
  Button as AntButton,
  Calendar as AntCalendar,
  Empty as AntEmpty,
  Flex as AntFlex,
  TypographyTitle as AntTitle,
} from 'ant-design-vue';
import dayjs, { Dayjs } from 'dayjs';
import { computed, ref } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'appointment-input'>;
  errors: string[];
  value: WidgetProps<'appointment-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'appointment-input'>['value']): void;
}>();

function getDayjsFromSlot(slot: WidgetProps<'appointment-input'>['slots'][0]): Dayjs {
  return dayjs(slot.begin);
}

function getInitialDate(): Dayjs {
  if (props.userProps.value) {
    return getDayjsFromSlot(props.userProps.slots[props.userProps.value]);
  } else {
    return getDayjsFromSlot(
      props.userProps.slots.sort(
        (a, b) => new Date(a.begin).getTime() - new Date(b.begin).getTime(),
      )[0],
    );
  }
}

const initialDate = getInitialDate();
const selectedDate = ref<Dayjs>(initialDate);
const selectedSlots = computed<
  { slot: WidgetProps<'appointment-input'>['slots'][0]; idx: number }[]
>(() => {
  const dateValue = selectedDate.value;
  return props.userProps.slots.reduce(
    (acc, slot, idx) =>
      new Date(slot.begin).getDate() === dateValue.date() &&
      new Date(slot.begin).getMonth() === dateValue.month() &&
      new Date(slot.begin).getFullYear() === dateValue.year()
        ? [...acc, { slot, idx }]
        : acc,
    [],
  );
});

const validRange = computed(() => {
  const { min, max } = props.userProps.slots.reduce(
    (acc, slot) => {
      const startDate = dayjs(slot.start).startOf('day');
      const endDate = dayjs(slot.end).startOf('day');

      if (!acc.min || startDate.isBefore(acc.min)) {
        acc.min = startDate;
      }
      if (!acc.max || endDate.isAfter(acc.max)) {
        acc.max = endDate;
      }

      return acc;
    },
    { min: null, max: null },
  );

  return [min, max.add(1, 'day')] as [Dayjs, Dayjs];
});

function getFirstAvailableDay(date: Dayjs) {
  const month = date.month();
  const year = date.year();
  const firstAvailableDay = props.userProps.slots.find((slot) => {
    const slotDate = dayjs(slot.begin);
    return slotDate.month() === month && slotDate.year() === year;
  });
  return dayjs(firstAvailableDay?.begin || date);
}

const panelChanging = ref(false);
function handleSelect(date: Dayjs) {
  if (panelChanging.value) {
    panelChanging.value = false;
    return;
  }
  selectedDate.value = date;
}

function handlePanelChange(dateOrString: Dayjs | string) {
  panelChanging.value = true;
  const date = dayjs(dateOrString);
  selectedDate.value = getFirstAvailableDay(date);
}

function slotClicked(idx: number) {
  emit('update:value', idx);
}

function disabledDate(current: Dayjs) {
  return !props.userProps.slots.some((slot) => current.isSame(dayjs(slot.begin), 'day'));
}
</script>

<style lang="scss" scoped>
.appointment-input {
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

:deep() {
  .ant-picker-calendar-mode-switch {
    display: none;
  }

  .ant-picker-calendar-header {
    justify-content: flex-start;
  }
}
</style>
