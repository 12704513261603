<template>
  <div class="list-input">
    <div
      v-for="(listItem, listIndex) in userProps.schemas"
      :key="listIndex"
      ref="listItems"
      :class="['list-item', 'card', { dragging: dragging === listIndex }]"
    >
      <div class="item-header">
        <ph-dots-six class="drag-handler" @mousedown="handleDragStart(listIndex)" />
        <ph-trash
          v-if="!props.userProps.min || value.length > props.userProps.min"
          class="close-button"
          @click="removeListItem(listIndex)"
        />
      </div>
      <div
        v-for="(widget, widgetIndex) in listItem"
        :key="widget.key ?? widgetIndex"
        class="widget list-widget"
      >
        <component
          :is="widget.type"
          :ref="(ref: Ref) => setWidgetRef(ref, listIndex, widget.key)"
          :value="getWidgetValue(listIndex, widget.key)"
          :errors="getWidgetErrors(listIndex, widget.key)"
          :user-props="widget"
          @update:value="updateWidgetValue(listIndex, widget.key, $event)"
          @update:errors="updateWidgetErrors(listIndex, widget.key, $event)"
        />
        <span
          v-if="'key' in widget && getWidgetErrors(listIndex, widget.key).length"
          class="span-error"
        >
          {{ getWidgetErrors(listIndex, widget.key).join('\n') }}
        </span>
      </div>
    </div>
    <button
      v-if="!props.userProps.max || value.length < props.userProps.max"
      class="icon-only-button add"
      @click="addListItem"
    >
      {{ props.userProps.addButtonText || '+' }}
    </button>
  </div>
</template>
<script lang="ts" setup>
import { SupportedLocales } from '../../common/i18n';
import { PhDotsSix, PhTrash } from '@phosphor-icons/vue';
import { cloneDeep, isEqual } from 'lodash';
import { Ref, onMounted, ref } from 'vue';
import { i18nProvider } from '../../common/i18n';
import type { WidgetProps } from '../../generated/widgetTypes';

const listItemsRef = ref<HTMLElement[]>([]);
const dragging = ref<number | null>(null);

const props = defineProps<{
  userProps: WidgetProps<'list-input'>;
  value: WidgetProps<'list-input'>['value'];
  errors: string[];
  locale: SupportedLocales
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'list-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const widgetRefs: Record<string, Ref> = {};

const setWidgetRef = (ref: Ref, listIndex: number, key: string) => {
  widgetRefs[listIndex + key] = ref;
};

function updateValue(newValue: typeof props.userProps.schemas) {
  if (isEqual(newValue, props.userProps.schemas)) return;
  const pageValues = (page: Record<string, any>[] | null) =>
    page?.reduce(
      (acc: any, item: any) => ({
        ...acc,
        [item.key]: item.value,
      }),
      {},
    );
  emit('update:value', newValue.map(pageValues));
}

function handleDragStart(idx: number) {
  dragging.value = idx;
}

function handleMouseMove(evt: MouseEvent) {
  if (dragging.value === null) return;
  const listItem = listItemsRef.value[dragging.value];
  listItem.style.top = `${evt.pageY}px`;
  listItem.style.left = `${evt.pageX}px`;
  listItem.style.transform = 'translate(-50%, -50%)';
  listItem.style.position = 'fixed';
}

const handleMouseUp = (evt: MouseEvent) => {
  if (dragging.value === null) return;

  const mouseY = evt.pageY;
  const newValue: typeof props.userProps.schemas = [];
  let found = 0;
  let foundSelf = 0;

  props.userProps.schemas.forEach((item, idx) => {
    if (idx === dragging.value) {
      foundSelf = 1;
      return;
    }
    const itemY = listItemsRef.value[idx].getBoundingClientRect().top;

    if (mouseY > itemY) {
      newValue[idx - foundSelf] = item;
      return;
    }

    if (!found) {
      newValue[idx + found - foundSelf] = item;
      found = 1;
    }
    newValue[idx + found - foundSelf] = item;
  });

  if (!found) {
    newValue.push(props.userProps.schemas[dragging.value]);
  }

  updateValue(newValue);

  dragging.value = null;

  listItemsRef.value.forEach((item) => {
    item.style.position = 'static';
    item.style.transform = 'translate(0, 0)';
  });
};

onMounted(() => {
  document.addEventListener('mouseup', handleMouseUp);
  document.addEventListener('mousemove', handleMouseMove);
});

const addListItem = () => {
  updateValue([...props.userProps.schemas, null]);
};

function updateWidgetValue(listIndex: number, widgetKey: string, newWidgetValue: any) {
  const newListValue = cloneDeep(props.userProps.schemas);

  const match = newListValue[listIndex]?.find((item: any) => item.key === widgetKey);

  match.value = newWidgetValue;

  updateValue(newListValue);
}

const widgetFrontendErrors = ref<Record<string, string[]>[]>([]);

function getWidgetErrors(listIndex: number, widgetKey: string): string[] {
  const match = props.userProps.schemas[listIndex]?.find((item: any) => item.key === widgetKey);

  const errors = [
    ...(match?.errors ?? []),
    ...(widgetFrontendErrors.value[listIndex]?.[widgetKey] ?? []),
  ];

  return errors.map((e) => i18nProvider.translateIfFound(e, props.locale));
}

function updateWidgetErrors(listIndex: number, widgetKey: string, newErrors: any) {
  if (!widgetFrontendErrors.value[listIndex]) widgetFrontendErrors.value[listIndex] = {};
  widgetFrontendErrors.value[listIndex][widgetKey] = newErrors;
  setListErrors();
}

function removeListItem(index: number) {
  const newValue = cloneDeep(props.userProps.schemas);
  newValue.splice(index, 1);
  updateValue(newValue);
}

function getWidgetValue(listIndex: number, widgetKey: string) {
  const match = props.userProps.schemas[listIndex]?.find((item: any) => item.key === widgetKey);

  if (!match) throw new Error(`Could not find widget value for ${widgetKey} index ${listIndex}`);

  return match.value;
}

function setListErrors() {
  if (
    widgetFrontendErrors.value.some((listItem) => {
      return Object.values(listItem).some((widgetFrontendErrors) => widgetFrontendErrors.length);
    })
  ) {
    emit('update:errors', ['There are errors in the list']);
    return;
  }
  emit('update:errors', []);
}
</script>

<style lang="scss" scoped>
@import '../../common/style/widgets.scss';
.list-input {
  .list-item {
    margin-bottom: 1.5rem;
    background-color: white;
    padding: 15px 30px 0 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &.dragging {
      opacity: 0.5;
      user-select: none;

      &:active {
        cursor: grabbing;
        z-index: 1;
      }
    }

    .item-header {
      display: flex;
      justify-content: space-between;
      .drag-handler {
        cursor: grab;
        width: 24px;
        height: 24px;
      }

      .close-button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        svg {
          width: 1.5rem;
          height: 1.5rem;
          path {
            fill: #333333;
          }
        }
        &:hover {
          color: #d35249;

          .icon {
            :deep() {
              path {
                fill: #d35249;
              }
            }
          }
        }
      }

      &:active {
        color: #fa675c;

        .icon {
          :deep() {
            path {
              fill: #fa675c;
            }
          }
        }
      }
    }

    .list-widget.widget {
      margin-bottom: 30px;
    }
  }
}

.span-error {
  @include auxTextError;
}
.icon-only-button {
  @include icon-only-button;
  min-height: 44px;
}
</style>
