<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <textarea
    ref="input"
    style="height: 100%"
    :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
    :disabled="userProps.disabled"
    :placeholder="userProps.placeholder"
    @input="handleInput(($event.target as any).value)"
  />
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'textarea-input'>;
  value: WidgetProps<'textarea-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'textarea-input'>['value']): void;
}>();

const handleInput = (value: string) => {
  input.value!.value = value;
  emit('update:value', value);
};

const input = ref<HTMLTextAreaElement>();

onMounted(() => {
  input.value!.value = props.value || '';
});

watch(
  () => props.value,
  () => {
    input.value!.value = props.value || '';
  },
);
</script>

<style scopped lang="scss">
textarea {
  resize: vertical;
}
</style>
