<template>
  <div class="cards-input" :style="{ '--grid-columns': userProps.columns ?? 2 }">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <div class="search">
      <input
        v-if="searchable"
        v-model="searchText"
        type="text"
        class="input"
        placeholder="Search..."
      />
    </div>
    <div :class="['cards', userProps.layout || 'list']">
      <div
        v-for="option in filterCardsBySearchText(options)"
        :key="option.title"
        :class="['card', 'clickable', userProps.layout || 'list', { disabled: userProps.disabled }]"
        :active="isSelected(option)"
        @click="cardClick(option)"
      >
        <div v-if="option.image" class="image-container">
          <img class="card-image" :src="option.image" />
        </div>
        <main class="text-container">
          <div v-if="option.topLeftExtra || option.topRightExtra" class="extra">
            <p v-if="option.topLeftExtra" class="left">
              {{ option.topLeftExtra }}
            </p>
            <p v-if="option.topRightExtra" class="right">
              {{ option.topRightExtra }}
            </p>
          </div>
          <h1 v-if="option.title" class="card-title">{{ option.title }}</h1>
          <h2 v-if="option.subtitle" class="card-subtitle">
            {{ option.subtitle }}
          </h2>
          <p v-if="option.description" class="card-description">
            {{ option.description }}
          </p>
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isEqual, omit } from 'lodash';
import { computed, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

function compareOptions(a: CardValue, b: CardValue): boolean {
  return isEqual(omit(a, ['image']), omit(b, ['image']));
}

type SelectionValue = WidgetProps<'cards-input'>['value'];
type CardValue = SelectionValue[number];

const props = defineProps<{
  userProps: WidgetProps<'cards-input'>;
  value: WidgetProps<'cards-input'>['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'cards-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
  (e: 'card-click', option: CardValue): void;
}>();

const options = computed(() => props.userProps.options ?? []);
const searchable = computed(() => props.userProps.searchable ?? false);
const searchText = ref<string>('');
const selectedCards = ref<SelectionValue>(props.value);

function filterCardsBySearchText(cards: SelectionValue): SelectionValue {
  if (!searchText.value) {
    return cards;
  }

  return cards.filter(
    (card) =>
      card.title?.toLowerCase().includes(searchText.value.toLowerCase()) ||
      card.subtitle?.toLowerCase().includes(searchText.value.toLowerCase()) ||
      card.description?.toLowerCase().includes(searchText.value.toLowerCase()),
  );
}

function isSelected(option: CardValue) {
  return selectedCards.value.some((v) => compareOptions(v, option));
}

function setMultipleSelection(option: CardValue) {
  const selected = isSelected(option);
  if (selected) {
    selectedCards.value = selectedCards.value.filter((v) => !compareOptions(v, option));
  } else {
    selectedCards.value = [...selectedCards.value, option];
  }
}

function setSingleSelection(option: CardValue) {
  const selected = isSelected(option);
  if (selected) selectedCards.value = [];
  else selectedCards.value = [option];
}

function cardClick(option: CardValue) {
  if (props.userProps.disabled) return;

  emit('card-click', option);

  props.userProps.multiple ? setMultipleSelection(option) : setSingleSelection(option);

  emit('update:value', selectedCards.value);
}

watch(
  () => props.value,
  () => {
    selectedCards.value = props.value;
  },
);
</script>

<style lang="scss" scoped>
.cards {
  margin-top: 10px;
  display: grid;
  gap: 30px;
  grid-template-columns: minmax(100px, 1fr);
  align-self: stretch;

  @media screen and (min-width: 760px) {
    &.grid {
      grid-template-columns: repeat(var(--grid-columns), minmax(100px, 1fr));
    }
  }

  .card {
    overflow: hidden;
    box-sizing: border-box;
    border: 3px solid transparent;
    transition: box-shadow 0.2s ease-out, border-color 0.2s ease-out;
    box-shadow: 0px 8px 16px rgba(112, 144, 176, 0.12);

    &.disabled {
      background: #f7f7f7f7;
    }

    &:hover:not(.disabled) {
      box-shadow: 0px 14px 60px rgba(112, 144, 176, 0.22);
    }

    &.clickable:not(.disabled) {
      cursor: pointer;
    }

    &[active='true'] {
      border: 3px solid var(--color-main);
    }

    .extra {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right {
        font-weight: 500;
      }
    }

    .card-title,
    .card-subtitle {
      font-size: 20px;
      margin: 0;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 500;
    }

    .card-subtitle {
      font-size: 16px;
    }

    .card-description {
      font-size: 16px;
      margin: 0;
      width: 100%;
      white-space: pre-wrap;
    }

    &.list {
      display: flex;
      flex-direction: row;
    }

    &.grid {
      display: flex;
      flex-direction: column;
    }
  }

  .text-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 20px 30px;
    flex-grow: 1;
    overflow: initial;
  }
  .image-container {
    display: flex;
    flex: 1;
    .card-image {
      object-fit: cover;
      width: 100%;
      max-height: 200px;
      margin: 0 auto;
      min-height: 0;
    }
  }
}
</style>
