<template>
  <div class="time-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <ant-time-picker
      v-model:value="internalValue"
      type="time"
      class="input"
      format="HH:mm"
      value-format="HH:mm"
      :disabled="userProps.disabled"
      @change="change"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

import { TimePicker as AntTimePicker } from 'ant-design-vue';

const props = defineProps<{
  userProps: WidgetProps<'time-input'>;
  errors: string[];
  value: WidgetProps<'time-input'>['value'];
}>();

const formatToString = (value: WidgetProps<'time-input'>['value']) => {
  return value
    ? `${value.hour.toString().padStart(2, '0')}:${value.minute.toString().padStart(2, '0')}`
    : undefined;
};

const internalValue = ref<string | undefined>(formatToString(props.value));

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'time-input'>['value']): void;
}>();

const change = (_: unknown, timeString: string) => {
  const [hour, minute] = timeString.split(':');
  const newValue = { hour: parseInt(hour), minute: parseInt(minute) };

  emit('update:value', newValue);
};

watch(
  () => props.value,
  (newValue) => {
    internalValue.value = formatToString(newValue);
  },
);
</script>

<style lang="scss" scoped>
@import '../../common/style/widgets.scss';

.input {
  position: relative;
}
</style>
