<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <ant-switch :checked="isEnabled" style="align-self: flex-start" @change="change" />
</template>

<script lang="ts" setup>
import { Switch as AntSwitch } from 'ant-design-vue';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'toggle-input'>;
  errors: string[];
  value: WidgetProps<'toggle-input'>['value'];
}>();

const isEnabled = ref(props.value);

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'toggle-input'>['value']): void;
}>();

const change = () => {
  if (props.userProps.disabled) return;
  isEnabled.value = !isEnabled.value;
  emit('update:value', isEnabled.value);
};

watch(
  () => props.value,
  () => {
    isEnabled.value = props.value;
  },
);
</script>

<style lang="scss" scoped></style>
