import FileOutput from '../widgets/FileOutput/component.vue';
import HtmlOutput from '../widgets/HtmlOutput/component.vue';
import IframeOutput from '../widgets/IframeOutput/component.vue';
import ImageOutput from '../widgets/ImageOutput/component.vue';
import LatexOutput from '../widgets/LatexOutput/component.vue';
import LinkOutput from '../widgets/LinkOutput/component.vue';
import MarkdownOutput from '../widgets/MarkdownOutput/component.vue';
import PandasOutput from '../widgets/PandasOutput/component.vue';
import PlotlyOutput from '../widgets/PlotlyOutput/component.vue';
import ProgressOutput from '../widgets/ProgressOutput/component.vue';
import TextOutput from '../widgets/TextOutput/component.vue';
import Start from '../widgets/Start/component.vue';
import End from '../widgets/End/component.vue';
import ErrorComponent from '../widgets/ErrorComponent/component.vue';

export const otherWidgets = {
  start: Start,
  end: End,
  error: ErrorComponent,
};
export const outputWidgets = {
  'file-output': FileOutput,
  'html-output': HtmlOutput,
  'iframe-output': IframeOutput,
  'image-output': ImageOutput,
  'latex-output': LatexOutput,
  'link-output': LinkOutput,
  'markdown-output': MarkdownOutput,
  'pandas-output': PandasOutput,
  'plotly-output': PlotlyOutput,
  'progress-output': ProgressOutput,
  'text-output': TextOutput,
};

export const isOutputType = (type: string) => outputWidgets.hasOwnProperty(type);
