<template>
  <div ref="latex" class="latex">{{ userProps.text }}</div>
</template>
<script lang="ts" setup>
import type { WidgetProps } from '../../generated/widgetTypes';
import { onMounted, ref } from 'vue';
import { loadScript } from '../../common/utils';

defineProps<{
  userProps: WidgetProps<'latex-output'>;
}>();

const latex = ref(null);

onMounted(async () => {
  await Promise.all([
    loadScript('https://polyfill.io/v3/polyfill.min.js?features=es6'),
    loadScript('https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js'),
  ]);

  (window.MathJax as any).typesetPromise([latex.value]);
});
</script>
<style lang="scss" scoped>
.latex {
  font-size: 1.5em;
}
</style>
