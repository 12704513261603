import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

export const initSentry = (app: App<Element>, router: Router) => {
  Sentry.init({
    app,
    dsn: 'https://92a7a6b6bf4d455dab113338d8518956@o1317386.ingest.sentry.io/6570769',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    enabled: import.meta.env.PROD,
    tracesSampleRate: 1.0,
    release: import.meta.env.VITE_SENTRY_RELEASE,
  });
};
