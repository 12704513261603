<template>
  <div class="number-slider-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <ant-slider
      ref="input"
      :class="[errors.length && 'error', 'slider']"
      :disabled="userProps.disabled"
      type="range"
      :min="userProps.min || 0"
      :max="userProps.max || 100"
      :step="userProps.step || 1"
      @change="handleInput($event as number)"
    />
  </div>
</template>

<script lang="ts" setup>
import { Slider as AntSlider } from 'ant-design-vue';
import { ref } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'number-slider-input'>;
  errors: string[];
  value: WidgetProps<'number-slider-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'number-slider-input'>['value']): void;
}>();

const handleInput = (value: number) => {
  const strValue = String(value);
  const numValue = valueAfterConstraints(Number(value));
  if (strValue != input.value!.value) input.value!.value = strValue;
  if (numValue != props.value) emit('update:value', numValue);
};

const valueAfterConstraints = (value: number) => {
  if (!value) return value;
  if (props.userProps.min && value < props.userProps.min) {
    return props.userProps.min;
  }
  if (props.userProps.max && value > props.userProps.max) {
    return props.userProps.max;
  }
  return value;
};

const input = ref<HTMLInputElement>();
</script>

<style scoped lang="scss">
:deep() {
  .ant-slider-handle::after {
    box-shadow: 0 0 0 1px var(--color-main);
  }
}
</style>