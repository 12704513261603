<template>
  <div class="password-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      v-if="pattern"
      ref="input"
      type="password"
      :pattern="pattern"
      :required="!!userProps.required"
      :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      :placeholder="userProps.placeholder"
      @input="handleInput(($event.target as any).value)"
      @blur="setErrors"
    />
    <input
      v-else
      ref="input"
      type="password"
      :required="!!userProps.required"
      :class="['input', errors.length && 'error']"
      :placeholder="userProps.placeholder"
      @input="handleInput(($event.target as any).value)"
      @blur="setErrors"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'password-input'>;
  errors: string[];
  value: string;
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: string): void;
}>();

const pattern = computed(() => {
  if (props.userProps.pattern) {
    return props.userProps.pattern;
  }

  const sizeRestrictionPattern = () => {
    const min = props.userProps.minLength ?? null;
    const max = props.userProps.maxLength ?? null;
    const size = props.userProps.size ?? null;
    let pattern = '';
    if (size) {
      pattern += `(.{${size},${size}})`;
    } else if (min && max) {
      pattern += `(.{${min},${max}})`;
    } else if (min) {
      pattern += `(.{${min},})`;
    } else if (max) {
      pattern += `(.{,${max}})`;
    }
    return pattern;
  };

  const lowerCaseRestrictionPattern = () => {
    const lower_required = props.userProps.lowercaseRequired;
    let pattern = '';
    if (lower_required) {
      pattern += '(?=.*[a-z])';
    }
    return pattern;
  };

  const upperCaseRestrictionPattern = () => {
    const upper_required = props.userProps.uppercaseRequired;
    let pattern = '';
    if (upper_required) {
      pattern += '(?=.*[A-Z])';
    }
    return pattern;
  };

  const digitRestrictionPattern = () => {
    const digit_required = props.userProps.digitRequired;
    let pattern = '';
    if (digit_required) {
      pattern += '(?=.*\\d)';
    }
    return pattern;
  };

  const specialCharacterRestrictionPattern = () => {
    const special_required = props.userProps.specialRequired;
    let pattern = '';
    if (special_required) {
      pattern += '(?=.*[!?@#$\\-%^&+=])';
    }
    return pattern;
  };

  const current_pattern = [
    lowerCaseRestrictionPattern,
    upperCaseRestrictionPattern,
    digitRestrictionPattern,
    specialCharacterRestrictionPattern,
    sizeRestrictionPattern,
  ].reduce((pt, func) => pt + func(), '');

  return current_pattern || null;
});

const setErrors = () => {
  const value = props.value;

  const verifyLowerCaseMatch = (): string | null => {
    const lowerCaseLetters = /[a-z]/g;
    const lower_required = props.userProps.lowercaseRequired;
    if (lower_required && !lowerCaseLetters.test(value)) {
      return 'Your password must have at least one lowercase letter';
    }
    return null;
  };

  const verifyUpperCaseMatch = (): string | null => {
    const upperCaseLetters = /[A-Z]/g;
    const upper_required = props.userProps.uppercaseRequired;
    if (upper_required && !upperCaseLetters.test(value)) {
      return 'Your password must have at leat one uppercase letter';
    }
    return null;
  };

  const verifyDigitMatch = (): string | null => {
    const digit_required = props.userProps.digitRequired;
    const numbers = /[0-9]/;
    if (digit_required && !numbers.test(value)) {
      return 'Your password must have at least one digit between 0 and 9';
    }
    return null;
  };

  const verifySpecialCaseMatch = (): string | null => {
    const special_required = props.userProps.specialRequired;
    const specialCaseLetters = /[!?@#$\-%^&+=]/g;
    if (special_required && !specialCaseLetters.test(value)) {
      return 'Your password must have at least one special character';
    }
    return null;
  };

  const verifySizeMatch = (): string | null => {
    const min = props.userProps.minLength ?? null;
    const max = props.userProps.maxLength ?? null;
    const size = props.userProps.size ?? null;
    const valueLength = value.length;
    if (size && valueLength !== size) {
      return `Your password must have ${size} characters`;
    }
    if (min && max && (valueLength < min || valueLength > max)) {
      return `Your password must have between ${min} and ${max} characters`;
    }
    if (min && valueLength < min) {
      return `Your password must have at least ${min} characters`;
    }
    if (max && valueLength > max) {
      return `Your password must have at most ${max} characters`;
    }
    return null;
  };

  const errors = [
    verifyDigitMatch,
    verifyLowerCaseMatch,
    verifySizeMatch,
    verifySpecialCaseMatch,
    verifyUpperCaseMatch,
  ].reduce((errAcc: string[], func) => {
    const output = func();
    if (output) errAcc.push(output);
    return errAcc;
  }, []);

  emit('update:errors', errors);
};

const handleInput = (value: string) => {
  emit('update:value', value);
};

const input = ref<HTMLInputElement>();

watch(
  () => props.value,
  () => {
    if (!input.value) return;
    input.value.value = props.value;
  },
);
</script>
