<template>
  <div class="checklist-input">
    <WidgetsLabel
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <AntCheckboxGroup
      v-model:value="selectedItems"
      :disabled="userProps.disabled"
      :options="userProps.options"
      @change="checkboxSelect($event as string[])"
    />
  </div>
</template>
<script lang="ts" setup>
import { CheckboxGroup as AntCheckboxGroup } from 'ant-design-vue';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'checklist-input'>;
  value: WidgetProps<'checklist-input'>['value'];
  errors: string[];
}>();

const selectedItems = ref<string[]>(props.value);

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'multiple-choice-input'>['value']): void;
}>();

const checkboxSelect = (value: string[]) => {
  emit('update:errors', []);
  emit('update:value', value);
};

watch(
  () => props.value,
  () => (selectedItems.value = props.value),
);
</script>
<style lang="scss" scoped>
:deep() {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    text-wrap: wrap;
    margin-top: 10px;
  }

  .ant-checkbox {
    // Used to align checkbox elements with fist line of label
    margin-top: 3px;
    align-self: flex-start;
  }
}
</style>
