import AppointmentInput from '../widgets/AppointmentInput/component.vue';
import CameraInput from '../widgets/CameraInput/component.vue';
import CardsInput from '../widgets/CardsInput/component.vue';
import CheckboxInput from '../widgets/CheckboxInput/component.vue';
import ChecklistInput from '../widgets/ChecklistInput/component.vue';
import CnpjInput from '../widgets/CnpjInput/component.vue';
import CodeInput from '../widgets/CodeInput/component.vue';
import CpfInput from '../widgets/CpfInput/component.vue';
import CurrencyInput from '../widgets/CurrencyInput/component.vue';
import CustomInput from '../widgets/CustomInput/component.vue';
import DateInput from '../widgets/DateInput/component.vue';
import DropdownInput from '../widgets/DropdownInput/component.vue';
import EmailInput from '../widgets/EmailInput/component.vue';
import FileInput from '../widgets/FileInput/component.vue';
import ImageInput from '../widgets/ImageInput/component.vue';
import ListInput from '../widgets/ListInput/component.vue';
import MultipleChoiceInput from '../widgets/MultipleChoiceInput/component.vue';
import NpsInput from '../widgets/NpsInput/component.vue';
import NumberInput from '../widgets/NumberInput/component.vue';
import NumberSliderInput from '../widgets/NumberSliderInput/component.vue';
import PandasRowSelectionInput from '../widgets/PandasRowSelectionInput/component.vue';
import PasswordInput from '../widgets/PasswordInput/component.vue';
import PhoneInput from '../widgets/PhoneInput/component.vue';
import RatingInput from '../widgets/RatingInput/component.vue';
import RichTextInput from '../widgets/RichTextInput/component.vue';
import TagInput from '../widgets/TagInput/component.vue';
import TextInput from '../widgets/TextInput/component.vue';
import TextareaInput from '../widgets/TextareaInput/component.vue';
import TimeInput from '../widgets/TimeInput/component.vue';
import ToggleInput from '../widgets/ToggleInput/component.vue';
import VideoInput from '../widgets/VideoInput/component.vue';

export const inputWidgets = {
  'appointment-input': AppointmentInput,
  'camera-input': CameraInput,
  'cards-input': CardsInput,
  'checkbox-input': CheckboxInput,
  'checklist-input': ChecklistInput,
  'cnpj-input': CnpjInput,
  'code-input': CodeInput,
  'cpf-input': CpfInput,
  'currency-input': CurrencyInput,
  'custom-input': CustomInput,
  'date-input': DateInput,
  'dropdown-input': DropdownInput,
  'email-input': EmailInput,
  'file-input': FileInput,
  'image-input': ImageInput,
  'list-input': ListInput,
  'multiple-choice-input': MultipleChoiceInput,
  'nps-input': NpsInput,
  'number-input': NumberInput,
  'number-slider-input': NumberSliderInput,
  'pandas-row-selection-input': PandasRowSelectionInput,
  'password-input': PasswordInput,
  'phone-input': PhoneInput,
  'rating-input': RatingInput,
  'rich-text-input': RichTextInput,
  'tag-input': TagInput,
  'text-input': TextInput,
  'textarea-input': TextareaInput,
  'time-input': TimeInput,
  'toggle-input': ToggleInput,
  'video-input': VideoInput,
};

export const isInputType = (type: string) => inputWidgets.hasOwnProperty(type);
