<template>
  <div class="text-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      ref="input"
      :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      :placeholder="userProps.placeholder"
      @keypress="handleKeyPress($event as KeyboardEvent)"
      @input="handleInput(($event.target as any).value)"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { checkMaskSize, getMaskedValue } from '../../common/mask';
import type { WidgetProps, Widgets } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'text-input'>;
  errors: string[];
  value: WidgetProps<'text-input'>['value'];
}>();

const input = ref<HTMLInputElement>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: Widgets['text-input']['value']): void;
}>();

const handleInput = (value: string) => {
  const maskedValue = props.userProps.mask ? getMaskedValue(props.userProps.mask, value) : value;
  input.value!.value = maskedValue;
  emit('update:value', maskedValue);
};

const handleKeyPress = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement;
  if (props.userProps.mask && checkMaskSize(props.userProps.mask, target.value)) {
    event.preventDefault();
  }
};

onMounted(() => {
  setMaskOnChange();
});

watch(
  () => props.value,
  () => {
    setMaskOnChange();
  },
);

function setMaskOnChange() {
  if (props.value && input.value) {
    if (props.userProps.mask) {
      input.value.value = getMaskedValue(props.userProps.mask, props.value);
    } else {
      input.value.value = props.value;
    }
  }
}
</script>
