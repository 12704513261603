<template>
  <div :height="containerHeight" :width="containerWidth">
    <widgets-label :label="userProps.label" :required="false" :hint="userProps.hint" />
    <a-table
      v-model:data="userProps.table.data"
      v-model:selected-indexes="selectedIndexes"
      enable-search
      :columns="columns"
      :rows-per-page="pageSize"
      :selectable="userProps.multiple ? 'multiple' : 'single'"
      :selection-disabled="userProps.disabled"
    />
  </div>
</template>

<script lang="ts" setup>
import { isEqual } from 'lodash';
import { computed, onMounted, ref, watch } from 'vue';
import ATable from '../../common/components/ATable.vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const DEFAULT_FORM_ROWS_PER_PAGE = 10;

const props = defineProps<{
  userProps: WidgetProps<'pandas-row-selection-input'>;
  errors: string[];
  value: WidgetProps<'pandas-row-selection-input'>['value'];
  runtime: string;
  containerHeight?: number;
  containerWidth?: number;
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'pandas-row-selection-input'>['value']): void;
}>();

const selectedIndexes = ref<number[]>([]);

const columns = computed(() => {
  return (
    props.userProps.displayIndex
      ? props.userProps.table.schema.fields
      : props.userProps.table.schema.fields.filter((f) => f.name != 'index')
  ).map((f) => ({ ...f, title: f.name.toString(), key: f.name.toString() }));
});

const setValue = (value: any[]) => {
  if (isEqual(value, props.value)) return;
  emit('update:value', value);
};

const pageSize = computed(() => {
  return props.userProps.pageSize ?? DEFAULT_FORM_ROWS_PER_PAGE;
});

watch(
  () => selectedIndexes.value,
  () => {
    setValue(selectedIndexes.value.map((idx: number) => props.userProps.table.data[idx]));
  },
);

onMounted(() => {
  selectedIndexes.value = [...props.value];
});
</script>

<style lang="scss" scoped></style>
