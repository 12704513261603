<template>
  <div class="rating-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <div ref="input" class="rating-icons">
      <div
        v-for="(icon, index) in filledIcons"
        :key="index"
        :class="['rating-icon', { active: active(index), disabled: userProps.disabled }]"
        tabindex="0"
        @click="change(index + 1)"
      >
        {{ icon }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const active = (index: number) => index < (selectedRate.value ?? 0);

const props = defineProps<{
  userProps: WidgetProps<'rating-input'>;
  errors: string[];
  value: WidgetProps<'rating-input'>['value'];
}>();

const selectedRate = ref(props.value);

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'rating-input'>['value']): void;
}>();

const filledIcons = computed(() =>
  Array(props.userProps.max ?? 5).fill(props.userProps.char ?? '⭐'),
);

const change = (value: number) => {
  if (props.userProps.disabled) return;
  selectedRate.value = value;
  emit('update:value', selectedRate.value);
};

watch(
  () => props.value,
  () => {
    selectedRate.value = props.value;
  },
);
</script>

<style lang="scss" scoped>
.rating-icons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .rating-icon {
    cursor: pointer;
    font-size: 24px;
    user-select: none;
    &.disabled {
      cursor: default;
    }
    &:not(.active) {
      filter: grayscale(1);
    }
    &:hover:not(.disabled) {
      transform: scale(1.5);
    }
  }
}
</style>
