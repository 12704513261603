<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <component
    :is="QuillEditor"
    v-if="QuillEditor"
    ref="input"
    style="height: 100%"
    :class="['input', errors.length && 'error', userProps.disabled && 'disabled']"
    :disabled="userProps.disabled"
    :placeholder="userProps.placeholder"
    @ready="setInitialValue"
    @update:content="handleInput()"
  />
</template>

<script lang="ts" setup>
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const QuillEditor: any = ref(null);

if (window.document) {
  import('@vueup/vue-quill').then((module) => {
    QuillEditor.value = module.QuillEditor;
  });
}

const props = defineProps<{
  userProps: WidgetProps<'textarea-input'>;
  errors: string[];
  value: WidgetProps<'textarea-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'textarea-input'>['value']): void;
}>();

const handleInput = () => {
  const value = input.value?.getHTML() ?? '';
  emit('update:value', value);
};

const input = ref<typeof QuillEditor>();

const setInitialValue = () => {
  if (props.value) {
    input.value?.setHTML(props.value);
  }
};

watch(
  () => props.value,
  () => {
    const value = input.value?.getHTML() || '';
    if (value !== props.value) setInitialValue();
  },
);
</script>

<style scopped lang="scss">
textarea {
  resize: vertical;
}

.ql-toolbar {
  background-color: white;
}
</style>
