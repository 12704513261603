import { formatDistance } from 'date-fns';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
export * from './redirect';

export function updateTitle(
  to: RouteLocationNormalizedLoaded,
  from: RouteLocationNormalizedLoaded,
) {
  const d = document as Document;
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    d.title = nearestWithTitle.meta.title as string;
  } else if (previousNearestWithMeta) {
    d.title = previousNearestWithMeta.meta.title as string;
  }
}

export function chooseRandomlyFromArray<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function dateDistance(date: Date): string {
  return formatDistance(date, Date.now(), { addSuffix: true });
}
