<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <div class="options">
    <button
      v-for="option in options"
      :key="option"
      :active="selectedItem !== option"
      :class="['option', 'button', { disabled: userProps.disabled }]"
      @click="select(option)"
    >
      {{ option }}
    </button>
  </div>
  <div class="nps-hints">
    <div class="nps-hint">{{ userProps.minHint }}</div>
    <div class="nps-hint">{{ userProps.maxHint }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'nps-input'>;
  value: WidgetProps<'nps-input'>['value'];
  errors: string[];
}>();

const selectedItem = ref(props.value);

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'nps-input'>['value']): void;
}>();

const max = computed(() => props.userProps.max ?? 10);
const min = computed(() => props.userProps.min ?? 0);

const options = computed(() =>
  Array(1 + max.value - min.value)
    .fill(null)
    .map((_, i) => i + min.value),
);

const select = (option: number) => {
  if (props.userProps.disabled) return;
  selectedItem.value = option;
  emit('update:value', selectedItem.value);
};

watch(
  () => props.value,
  () => {
    selectedItem.value = props.value;
  },
);
</script>

<style lang="scss" scoped>
@import '../../common/style/colors.scss';
.options {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;

  .option {
    padding: 5px 0 !important;
    flex-grow: 1;

    &[active='true'] {
      background-color: transparent !important;
      color: var(--color-main);
    }
  }
}

.nps-hints {
  display: flex;
  justify-content: space-between;
  line-height: 20px;

  .nps-hint {
    color: $secondary-light;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
