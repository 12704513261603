<template>
  <widgets-label :label="userProps.label" :required="!!userProps.required" :hint="userProps.hint" />
  <div ref="editor" class="input code-editor" style="height: 500px"></div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'code-input'>;
  errors: string[];
  value: WidgetProps<'code-input'>['value'];
}>();

const emit = defineEmits<{
  (e: 'update:value', value: WidgetProps<'code-input'>['value']): void;
  (e: 'update:errors', errors: string[]): void;
}>();

const editor = ref<HTMLDivElement>();

const initEditor = async (element: HTMLElement, code: string, language?: string) => {
  const monaco = await import('monaco-editor');

  const editor = monaco.editor.create(element, {
    language,
    value: code,
    minimap: {
      enabled: false,
    },
    readOnly: props.userProps.disabled,
    contextmenu: true,
    automaticLayout: true,
    tabSize: 4,
    renderWhitespace: 'none',
    guides: {
      indentation: false,
    },
    theme: 'vs',
    fontFamily: 'monospace',
    lineNumbers: 'on',
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    renderLineHighlight: 'all',
    scrollbar: {
      alwaysConsumeMouseWheel: false,
    },
  });

  editor.onDidChangeModelContent(() => {
    emit('update:value', String(editor.getValue()));
  });
};

onMounted(() => {
  initEditor(editor.value!, props.value ?? '', props.userProps.language);
});
</script>

<style scoped lang="scss">
.code-editor {
  height: auto;
  min-height: 500px;
}
</style>
