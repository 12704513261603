<template>
  <div class="multiple-choice-input">
    <WidgetsLabel
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <AntRadioGroup
      v-if="!userProps.multiple"
      v-model:value="radioSelectedItem"
      :disabled="userProps.disabled"
      :options="userProps.options"
      @change="radioSelect($event.target.value)"
    />
    <AntCheckboxGroup
      v-else
      v-model:value="checkboxSelectedItem"
      :disabled="userProps.disabled"
      :options="userProps.options"
      @change="checkboxSelect($event as string[])"
    />
  </div>
</template>

<script lang="ts" setup>
import { CheckboxGroup as AntCheckboxGroup, RadioGroup as AntRadioGroup } from 'ant-design-vue';
import { ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import type { WidgetProps } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'multiple-choice-input'>;
  errors: string[];
  value: WidgetProps<'multiple-choice-input'>['value'];
}>();

const radioSelectedItem = ref<null | string>(props.value[0]);
const checkboxSelectedItem = ref<string[]>(props.value);

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: WidgetProps<'multiple-choice-input'>['value']): void;
}>();

const radioSelect = (value: string) => {
  emit('update:errors', []);
  emit('update:value', [value]);
};

const checkboxSelect = (value: string[]) => {
  emit('update:errors', []);
  emit('update:value', value);
};

watch(
  () => props.value,
  () => {
    radioSelectedItem.value = props.value[0];
    checkboxSelectedItem.value = props.value;
  },
);
</script>
<style scoped lang="scss">
:deep() {
  .ant-checkbox-group,
  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    text-wrap: wrap;
    margin-top: 10px;
  }

  .ant-radio,
  .ant-checkbox {
    // Used to align radio and checkbox elements with fist line of label
    margin-top: 3px;
    align-self: flex-start;
  }
}
</style>
