<template>
  <div class="cpf-input">
    <widgets-label
      :label="userProps.label"
      :required="!!userProps.required"
      :hint="userProps.hint"
    />
    <input
      ref="input"
      :class="['input', props.errors.length && 'error', userProps.disabled && 'disabled']"
      :disabled="userProps.disabled"
      :placeholder="userProps.placeholder"
      @input="handleInput(($event.target as any).value)"
      @keypress="handleKeyPress($event as KeyboardEvent)"
      @change="setErrors"
      @blur="setErrors"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import WidgetsLabel from '../../common/components/Label.vue';
import { checkMaskSize, getMaskedValue } from '../../common/mask';
import type { WidgetProps, Widgets } from '../../generated/widgetTypes';

const props = defineProps<{
  userProps: WidgetProps<'cpf-input'>;
  value: Widgets['cpf-input']['value'];
  errors: string[];
}>();

const emit = defineEmits<{
  (e: 'update:errors', errors: string[]): void;
  (e: 'update:value', value: Widgets['cpf-input']['value']): void;
}>();

const CPF_MASK = '000.000.000-00';

const validateCPF = (maskedCPF: string) => {
  const cpfDigits = maskedCPF.split('.').join('').split('-').join('');
  let sum;
  let remainder;
  sum = 0;
  if (cpfDigits == '00000000000') return false;

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpfDigits.substring(i - 1, i)) * (11 - i);
  remainder = (sum * 10) % 11;

  if (remainder == 10 || remainder == 11) remainder = 0;
  if (remainder != parseInt(cpfDigits.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpfDigits.substring(i - 1, i)) * (12 - i);
  remainder = (sum * 10) % 11;

  if (remainder == 10 || remainder == 11) remainder = 0;
  if (remainder != parseInt(cpfDigits.substring(10, 11))) return false;
  return true;
};

const isValid = (maskedValue?: string) => {
  if (!maskedValue) return true;
  return checkMaskSize(CPF_MASK, maskedValue) && validateCPF(maskedValue);
};

const handleInput = (value: string) => {
  const maskedValue = getMaskedValue(CPF_MASK, value);

  if (!input.value) return;

  input.value.value = maskedValue;

  emit('update:value', value);
};

const handleKeyPress = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement;
  if (checkMaskSize(CPF_MASK, target.value) && validateCPF(target.value)) {
    event.preventDefault();
  }
};

const setErrors = () => {
  const errors = [];

  if (!isValid(getMaskedValue(CPF_MASK, props.value)))
    errors.push(props.userProps.invalidMessage ?? '');

  emit('update:errors', errors);
};

const input = ref<HTMLInputElement>();

onMounted(() => {
  if (!input.value) return;
  input.value.value = getMaskedValue(CPF_MASK, props.value);
});

watch(
  () => props.value,
  () => {
    if (!input.value) return;
    input.value.value = getMaskedValue(CPF_MASK, props.value);
  },
);
</script>
